import { Box, Flex, Heading, Link, Text, useDisclosure } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { useTrackTestUpgradeRequired } from "Hooks/use-track-test-upgrade-required"
import { Dispatch } from "Redux/app-store"
import {
  unarchiveUsabilityTest,
  upgradeUsabilityTest,
} from "Redux/reducers/usability-tests/action-creators"
import { axios } from "Services/axios"
import { PermittedDeviceType } from "Types"
import { LinkRecruitmentMethod } from "UsabilityHub/components/LinkRecruitmentMethod"
import { OrderSummaryList } from "UsabilityHub/components/OrderSummary/OrderSummaryList"
import { RecruitMethodSelection } from "UsabilityHub/components/RecruitMethodSelection/RecruitMethodSelection"
import { RecruitmentLinkFormModal } from "UsabilityHub/components/RecruitmentLink/RecruitmentLinkFormModal"
import { UpgradeUsabilityTestPaywall } from "UsabilityHub/components/UpgradeUsabilityTestBanner/UpgradeUsabilityTestPaywall"
import { UpgradeUsabilityTestSuccessModal } from "UsabilityHub/components/UpgradeUsabilityTestBanner/UpgradeUsabilityTestSuccessModal"
import {
  useCurrentAccount,
  useRefreshCurrentAccount,
} from "UsabilityHub/hooks/useCurrentAccount"
import { usePlans } from "UsabilityHub/hooks/usePlans"
import {
  usePollTestOrders,
  useRefreshTestOrders,
} from "UsabilityHub/hooks/usePollTestOrders"
import {
  RecruitPageUsabilityTest,
  useRefreshUsabilityTestRecruit,
} from "UsabilityHub/hooks/useUsabilityTestRecruit"
import { isRecruitmentAllowed as isUsabilityTestRecruitmentAllowed } from "Utilities/usability-test"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useUsabilityTestPage } from "~/api/generated/usabilityhub-components"
import OrdersApi from "~/api/ordersApi"
import { DiscountOrderBanner } from "./DiscountOrderBanner"
import { PanelRecruitmentMethod } from "./PanelRecruitmentMethod"
import { useUsabilityTestRecruitLink } from "./useUsabilityTestRecruitLink"

interface Props {
  usabilityTestContainsUrls: boolean
  usabilityTest: RecruitPageUsabilityTest
}

export const UsabilityTestRecruit: React.FC<Props> = ({
  usabilityTest,
  usabilityTestContainsUrls,
}) => {
  const { isOpen: isLinkModalOpen, onOpen, onClose } = useDisclosure()

  // Some info is only available at the page level
  const { data: usabilityTestPageData, status: pageDataState } =
    useUsabilityTestPage({
      pathParams: { usabilityTestId: usabilityTest.unique_id },
    })

  const [upgradeSuccessModalOpen, setUpgradeSuccessModalOpen] = useState(false)
  const dispatch = useDispatch<Dispatch>()

  // Selectors
  const currentAccount = useCurrentAccount()
  const refreshCurrentAccount = useRefreshCurrentAccount()
  const allPlans = usePlans()

  const { data: ordersData } = usePollTestOrders(usabilityTest.unique_id)
  const orders = ordersData?.orders ?? []
  const thirdPartyOrders = ordersData?.third_party_orders ?? []

  const refresh = useRefreshTestOrders(usabilityTest.unique_id)
  const refreshUsabilityTest = useRefreshUsabilityTestRecruit(
    usabilityTest.unique_id
  )

  const onUpgrade = async (creditsToBePurchased: number) => {
    await dispatch(
      upgradeUsabilityTest(usabilityTest.unique_id, creditsToBePurchased)
    )
    return Promise.allSettled([refreshCurrentAccount(), refreshUsabilityTest()])
  }
  const onUnarchive = async () => {
    await dispatch(
      unarchiveUsabilityTest(usabilityTest.unique_id, usabilityTest.name)
    )
    await Promise.allSettled([refresh(), refreshUsabilityTest()])
  }

  // We only need to show monthly paid plans here as the annual paid plans have the same features
  const visiblePlans =
    allPlans?.filter(
      (plan) =>
        !plan.discontinued &&
        plan.unique_id !== "enterprise" &&
        plan.unique_id !== Constants.FREE_PLAN_UNIQUE_ID &&
        plan.interval === "month"
    ) ?? []

  const isRecruitmentAllowed = isUsabilityTestRecruitmentAllowed(
    usabilityTest,
    currentAccount,
    visiblePlans
  )

  useTrackTestUpgradeRequired(
    !isRecruitmentAllowed,
    usabilityTest.id,
    "recruitment_page"
  )

  const canManageLink = isRecruitmentAllowed && !usabilityTest.admin_disabled

  const [recruitmentLink, setRecruitmentLink] = useUsabilityTestRecruitLink(
    usabilityTest.unique_id
  )

  const dismissDiscount = () => {
    return axios.post(OrdersApi.dismissDiscount.path())
  }

  // Don't show link recruitment for external studies
  const showLinkRecruitment =
    pageDataState === "success" &&
    !usabilityTestPageData?.usability_test.is_external_study

  return (
    <>
      {isRecruitmentAllowed && currentAccount.show_discounted_order_banner && (
        <DiscountOrderBanner onDismiss={dismissDiscount} />
      )}
      {!isRecruitmentAllowed && (
        <UpgradeUsabilityTestPaywall
          usabilityTest={usabilityTest}
          onUpgradeTest={(creditsToBePurchased: number) => {
            onUpgrade(creditsToBePurchased)
            setUpgradeSuccessModalOpen(true)
          }}
          visiblePlans={visiblePlans}
        />
      )}
      {upgradeSuccessModalOpen && (
        <UpgradeUsabilityTestSuccessModal
          onContinue={() => setUpgradeSuccessModalOpen(false)}
        />
      )}
      {usabilityTest.archived && (
        <Box maxW="6xl" mx="auto" mt={10}>
          <Heading mb={1}>This test is currently archived.</Heading>
          <Text>
            This test has been archived, and can{"\u2019"}t currently be taken
            by participants. To re-enable recruitment,&nbsp;
            <Link href="#" onClick={onUnarchive}>
              unarchive this test
            </Link>
            .
          </Text>
        </Box>
      )}
      {!usabilityTest.archived && (
        <Flex justify="center" mx={6}>
          <Box w="100%" maxW="6xl" mx="auto">
            <RecruitMethodSelection mb={8}>
              <PanelRecruitmentMethod
                isRecruitmentAllowed={isRecruitmentAllowed}
                showTestContainsLinkAlert={usabilityTestContainsUrls}
                usabilityTest={usabilityTest}
              />
              {showLinkRecruitment && (
                <LinkRecruitmentMethod
                  onManageLink={canManageLink ? onOpen : undefined}
                  recruitmentLink={recruitmentLink}
                  isTestSetLink={false}
                />
              )}
            </RecruitMethodSelection>

            <OrderSummaryList
              orders={orders}
              thirdPartyOrders={thirdPartyOrders}
              usabilityTest={usabilityTest}
              permittedDeviceType={
                usabilityTest.permitted_device_type as PermittedDeviceType
              }
              recruitmentLink={recruitmentLink}
              onManageLink={canManageLink ? onOpen : undefined}
            />
          </Box>
        </Flex>
      )}

      {isLinkModalOpen && (
        <RecruitmentLinkFormModal
          account={currentAccount}
          onClose={onClose}
          onUpgrade={onUpgrade}
          isTestSetLink={false}
          linkable={usabilityTest}
          isUpgraded={usabilityTest.upgraded}
          recruitmentLink={recruitmentLink}
          setRecruitmentLink={setRecruitmentLink}
          recordingTypes={usabilityTest.test_recording_types}
        />
      )}
    </>
  )
}
