import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text,
} from "@chakra-ui/react"
import { PanelOrderCompositeIcon } from "Shared/components/CompositeIcons/CompositeIcons"
import Created from "UsabilityHub/components/Created"
import { ModeratedStudyOrderSummaryCardExpandedSection } from "UsabilityHub/components/OrderSummary/ModeratedStudyOrderSummaryCardExpandedSection"
import React from "react"
import { useGetModeratedStudy } from "~/api/generated/usabilityhub-components"
import { ModeratedStudyOrder } from "~/api/generated/usabilityhubSchemas"
import { Stats } from "./ModeratedStudyCard/Stats"
import { RecruitingButton } from "./RecruitingButton"

type Props = {
  moderatedStudyId: string
  orders: ModeratedStudyOrder[]
}

export const PanelOrderList: React.FC<Props> = ({
  moderatedStudyId,
  orders,
}) => {
  const { data: moderatedStudy } = useGetModeratedStudy({
    pathParams: {
      moderatedStudyId,
    },
  })

  if (orders.length === 0) return null
  if (!moderatedStudy) return null

  return (
    <Accordion allowMultiple>
      {orders.map((order) => {
        return (
          <AccordionItem
            key={order.id}
            bg="white"
            rounded="lg"
            borderWidth={1}
            borderColor="gray.300"
            mt={4}
          >
            <AccordionButton p={5}>
              <OrderSummary order={order} />
            </AccordionButton>

            <AccordionPanel p={6}>
              <ModeratedStudyOrderSummaryCardExpandedSection order={order} />
            </AccordionPanel>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}

const OrderSummary: React.FC<{ order: ModeratedStudyOrder }> = ({ order }) => {
  return (
    <Flex w="full" align="center" gap={4}>
      <PanelOrderCompositeIcon />

      <Flex direction="column" align="flex-start" flexGrow={1}>
        <Text color="text.primary" fontWeight="bold">
          {order.name}
        </Text>
        <Text
          textAlign="left" // Inside a button which defaults to center align
          w="full"
          fontSize="sm"
          color="text.secondary"
          isTruncated
        >
          {order.handpick_panelists ? "Handpick" : "Automatic"}
        </Text>
      </Flex>

      <RecruitingButton order={order} />

      <Flex px={{ base: 0, lg: 6 }}>
        <Stats
          applicantCounts={order.applicant_counts}
          bookingCounts={order.booking_counts}
          maxBookings={order.requested_response_count}
        />
      </Flex>

      <Created
        createdAt={order.created_at}
        name={order.creator?.name ?? ""}
        email={order.creator?.email ?? ""}
        avatarUrl={order.creator?.avatar_url ?? ""}
      />

      <AccordionIcon
        mx="10px" // This makes it the same total width (40px) as the button on recruitment links
      />
    </Flex>
  )
}
