import {
  Button,
  Icon,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
} from "@chakra-ui/react"
import { Input } from "@chakra-ui/react"
import { Flex } from "@chakra-ui/react"
import { getCurrentResponse } from "Redux/reducers/current-response/selectors"
import { Copy06OutlineIcon } from "Shared/icons/untitled-ui/Copy06OutlineIcon"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { OuterProps } from "../../props"

type Props = {
  onClose: () => void
  validCode: boolean
  onCheckCode: (code: string) => void
  onReport: () => void
  externalStudy: NonNullable<
    OuterProps["usabilityTestSection"]["external_study"]
  >
}

export const ExternalStudyControlPanel: React.FC<Props> = ({
  onClose,
  validCode,
  onCheckCode,
  onReport,
  externalStudy,
}) => {
  const response = useSelector(getCurrentResponse)
  const [completionCode, setCompletionCode] = useState("")
  const participantId = response?.id ?? "preview"

  return (
    <Flex direction="column" p={4} gap={4} h="full">
      {externalStudy.participation_strategy === "copy_paste" && (
        <>
          <Text>
            You'll need to enter your Participant ID in the external task to
            link it to your account. You can copy from here and paste it in:
          </Text>

          <InputGroup rounded="md">
            <InputLeftAddon>Participant ID</InputLeftAddon>
            <Flex
              flexGrow={1}
              align="center"
              borderTopWidth={1}
              borderBottomWidth={1}
              px={3}
              bg="white"
            >
              <Text>{participantId}</Text>
            </Flex>
            <InputRightAddon>
              <Icon
                as={Copy06OutlineIcon}
                aria-label="Copy participantId to clipboard"
                onClick={() => {
                  // TODO: This doesn't seem to work from the PIP window
                  navigator.clipboard.writeText(participantId.toString())
                }}
                cursor="pointer"
              />
            </InputRightAddon>
          </InputGroup>
        </>
      )}
      {externalStudy.completion_strategy === "prompt" &&
        (validCode ? (
          <>
            <Text>
              When you're ready, click here to close the tab and finish the
              task:
            </Text>

            <Button colorScheme="brand.primary" onClick={onClose}>
              End task
            </Button>
          </>
        ) : (
          <>
            <Text>
              Once you've completed the task, you will be given a completion
              code. Enter it here:
            </Text>

            <Flex gap={2}>
              <Input
                flexGrow={1}
                placeholder="Completion code"
                value={completionCode}
                onChange={(e) => setCompletionCode(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onCheckCode(completionCode)
                  }
                }}
              />
              <Button
                flexShrink={0}
                colorScheme="brand.primary"
                onClick={() => onCheckCode(completionCode)}
              >
                Check code
              </Button>
            </Flex>
          </>
        ))}

      {externalStudy.completion_strategy === "redirect_url" && (
        <Text>
          You will automatically complete the task when it redirects you at the
          end.
        </Text>
      )}

      <Text>
        If you are unable to complete the task due to an issue, click here:
      </Text>

      <Button colorScheme="red" onClick={onReport}>
        Report the task
      </Button>
    </Flex>
  )
}
