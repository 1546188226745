import React, { Component, ReactElement, ReactNode } from "react"
import { connect } from "react-redux"

import { SkipUsabilityTestModal } from "Components/skip-usability-test-modal/skip-usability-test-modal"
import { Dispatch } from "Redux/app-store"
import { cancelResponse } from "Redux/reducers/current-response/action-creators"
import { isBadRequestError, isDeprecatedAxiosError } from "Services/axios"
import {
  AutomaticDeletionReason,
  Language,
  ParticipantDeletionReason,
  ParticipantResponse,
  UsabilityTestSectionQuestion as Question,
  Response,
  ResponseAnswer,
  ResponseDemographicProfile,
  Screenshot,
  TestBranding,
  ThankYouMessageCopy,
  Unpersisted,
  UsabilityTestSection,
  UsabilityTestSectionType,
  WelcomeMessageCopy,
} from "Types"
import { abandonedErrorContent } from "UsabilityHub/components/UsabilityTest/content-factory/abandoned-error-content"
import {
  maliciousErrorContent,
  tooFastErrorContent,
} from "UsabilityHub/components/UsabilityTest/content-factory/automatically-deleted-content"
import demographicQuestionsContent from "UsabilityHub/components/UsabilityTest/content-factory/demographic-questions-content/demographic-questions-content.container"
import { disconnectedErrorContent } from "UsabilityHub/components/UsabilityTest/content-factory/disconnected-error-content"
import { loadErrorContent } from "UsabilityHub/components/UsabilityTest/content-factory/load-error-content"
import { submitErrorContent } from "UsabilityHub/components/UsabilityTest/content-factory/submit-error-content"
import submittingContent from "UsabilityHub/components/UsabilityTest/content-factory/submitting-content"
import thankYouContent from "UsabilityHub/components/UsabilityTest/content-factory/thank-you-content/thankYouContent"
import { timedOutErrorContent } from "UsabilityHub/components/UsabilityTest/content-factory/timed-out-error-content"
import welcomeContent from "UsabilityHub/components/UsabilityTest/content-factory/welcome-content"
import UsabilityTestLayout, {
  AppearanceProps as LayoutAppearanceProps,
  LayoutState,
} from "UsabilityHub/components/UsabilityTestLayout/UsabilityTestLayout"
import UsabilityTestSectionQuestion from "UsabilityHub/components/UsabilityTestSectionQuestion/UsabilityTestSectionQuestion"
import UsabilityTestSectionTask from "UsabilityHub/components/UsabilityTestSectionTask/UsabilityTestSectionTask"
import { ROUTES } from "UsabilityHub/views/routes"
import { beforeUnloadHandler } from "Utilities/before-unload-handler"
import { reportError } from "Utilities/error"
import { minDuration, neverResolve } from "Utilities/promise"
import {
  ResponsePhase,
  ResponseState,
  isDeleted,
  isPanelOrdered,
  isPreview,
  isRecruited,
} from "Utilities/response"
import { isParticipantDeletionReason } from "Utilities/response-deletion-reason"
import { isSuccess, promiseToResult } from "Utilities/result"
import type {
  RecordingType,
  RecruitmentLink,
} from "~/api/generated/usabilityhubSchemas"
import TestInterfaceApi from "~/api/testInterfaceApi"
import { InformationTaskMedia } from "../UsabilityTestSectionTask/SectionTasks/InformationTaskMedia"
import { UsabilityTestPreviewBanner } from "./UsabilityTestPreviewBanner"
import recordingSetupGuideContent from "./content-factory/recording-setup-guide-content/RecordingSetupGuideContent"
import { unhandledDeletionReasonContent } from "./content-factory/unhandled-deletion-reason-content"
import { TestBrandingContextProvider } from "./context/testBranding"
import { DisconnectedBanner } from "./disconnected-banner"

interface CallbackProps {
  addResponseAnswer: (answer: Readonly<Unpersisted<ResponseAnswer>>) => void
  addResponseDemographics: (
    demographics: Partial<ResponseDemographicProfile>
  ) => void
  keepResponseAlive: () => void
  submitResponse: () => Promise<void>
  loadUsabilityTest: (isPreview: boolean) => Promise<void>
  updateResponse: (fields: Partial<Response>) => void
  onSkip: (reason: ParticipantDeletionReason) => Promise<void>
}

interface DataProps {
  didLoadFail: boolean
  isLoaded: boolean
  isConnected: boolean
  language: Language
  recruitmentLink: RecruitmentLink
  redirectLink: string | null
  response: ParticipantResponse
  responseState: ResponseState
  screenshots: ReadonlyArray<Screenshot>
  testBranding: TestBranding
  thankYouCopy: ThankYouMessageCopy
  welcomeCopy: WelcomeMessageCopy
  hasPlayableScreenshot: boolean
  allowedRecordingTypes: RecordingType[]
  areAllRecordingsUploaded: boolean
  cleanupScreenStream: () => void
  recordingSetupGuideFinished: boolean
}

type Props = CallbackProps & DataProps

function sectionLayoutState(
  usabilityTestSection: UsabilityTestSection,
  question: Question | null
): LayoutState {
  const { type } = usabilityTestSection

  if (type === UsabilityTestSectionType.Information) {
    if (question !== null) {
      reportError(
        new TypeError(`Section of type ${type} should not have any questions`),
        { extra: { usabilityTestSection, question } }
      )
    }

    if (usabilityTestSection.section_screenshots.length > 0) {
      return LayoutState.ZoomableSplit
    }

    return LayoutState.FocusQuestion
  }

  // If there is no current question, focus the media pane.
  if (question === null) {
    return LayoutState.FocusMedia
  }

  // We have a question - some sections must be hidden during questions,
  // check if this is one of them...
  switch (usabilityTestSection.type) {
    case UsabilityTestSectionType.FiveSecondTest:
    case UsabilityTestSectionType.Questions:
      return LayoutState.FocusQuestion
    case UsabilityTestSectionType.PrototypeTask:
      return LayoutState.FocusMedia
  }

  // ...Otherwise it's a split with optional zoom.
  return LayoutState.ZoomableSplit
}

function sectionContent(props: Props): LayoutAppearanceProps {
  const {
    addResponseAnswer,
    responseState: { usabilityTestSection, question, responseSection },
  } = props

  // TODO: Enforce statically.
  if (usabilityTestSection === null) throw new Error("Nah!")

  const taskNode: ReactNode = (
    <UsabilityTestSectionTask
      key={usabilityTestSection.id}
      usabilityTestSection={usabilityTestSection}
      responseSection={responseSection}
    />
  )

  const questionNode: ReactElement | null = question && (
    <UsabilityTestSectionQuestion
      section={usabilityTestSection}
      key={question.id}
      question={question}
      onAnswerSubmit={addResponseAnswer}
    />
  )

  const content = {
    isReportButtonVisible: usabilityTestSection.type !== "prototype_task",
    layoutState: sectionLayoutState(usabilityTestSection, question),
    questionContent: questionNode,
    mediaContent: taskNode,
  }

  if (usabilityTestSection.type === "information") {
    const hasMedia = usabilityTestSection.section_screenshots.length > 0
    return {
      ...content,
      questionContent: taskNode,
      mediaContent: hasMedia ? (
        <InformationTaskMedia usabilityTestSection={usabilityTestSection} />
      ) : null,
    }
  }

  return content
}

function testContent(props: Props): LayoutAppearanceProps {
  switch (props.responseState.phase) {
    case ResponsePhase.DemographicQuestions: {
      const { addResponseDemographics, recruitmentLink, response } = props
      const demographicQuestionsProps = {
        addResponseDemographics,
        recruitmentLink,
        thirdPartyOrderId: response.third_party_order_id,
        permitUnload,
      }
      return demographicQuestionsContent(demographicQuestionsProps)
    }
    case ResponsePhase.TakingTest:
      return sectionContent(props)
    case ResponsePhase.Complete: {
      permitUnload()
      return thankYouContent(props)
    }
  }
}

const [preventUnload, permitUnload] = beforeUnloadHandler()

interface State {
  isAbleToRetrySubmit: boolean
  isSkipModalOpen: boolean
  isSubmitting: boolean
  startTime: number | null
  submitError: string | null
}

class Impl extends Component<Props, State> {
  state: State = {
    isAbleToRetrySubmit: false,
    isSkipModalOpen: false,
    isSubmitting: false,
    startTime: null,
    submitError: null,
  }

  // -- Event handlers --

  private handleStart = () => {
    const { isLoaded, response } = this.props

    if (!isLoaded) {
      reportError(new Error("Started test before screenshots finished loading"))
    }

    if (isRecruited(response) && !isPreview(response)) {
      // Recruited participant only need to be protected against drop-off once
      // they have started the test as they can come back later and finish it.
      preventUnload()
    }
    this.setState({ startTime: performance.now() })
  }

  private handleOpenSkipModal = () => {
    this.setState({ isSkipModalOpen: true })
  }

  private handleCloseSkipModal = () => {
    this.setState({ isSkipModalOpen: false })
  }

  // -- Private interface --

  private submit = async () => {
    if (isPreview(this.props.response)) return permitUnload()
    try {
      this.setState({ isSubmitting: true })

      // Showing the submission screen for a short time can appear janky so we
      // fill out the time if required.
      const result = await minDuration(
        1800,
        promiseToResult(this.props.submitResponse())
      )

      if (isSuccess(result)) {
        permitUnload()
        this.setState({ submitError: null })
      } else {
        const { error } = result
        if (isDeprecatedAxiosError(error)) {
          if (error.response !== undefined) {
            if (isBadRequestError<string>(error)) {
              // Invalid request, server returns reason.
              this.setState({
                submitError: error.response.data,
                isAbleToRetrySubmit: false,
              })
            } else {
              // Unexpected response.
              this.setState({
                submitError: `Something went wrong while submitting your response: ${error.response.statusText}`,
                isAbleToRetrySubmit: true,
              })
              reportError(error)
            }
          } else if (error.request && error.request.status === 0) {
            // Disconnected client.
            this.setState({
              submitError:
                "We were unable to submit your response, you appear to be offline",
              isAbleToRetrySubmit: true,
            })
          }
        } else {
          // A non-network error occurred.
          this.setState({
            submitError: "An unexpected error occurred.",
            isAbleToRetrySubmit: true,
          })
          reportError(error)
        }
      }
    } finally {
      this.setState({ isSubmitting: false })
    }
  }

  // -- Lifecycle --

  UNSAFE_componentWillReceiveProps({
    responseState: { phase },
    updateResponse,
    areAllRecordingsUploaded,
  }: Props) {
    if (
      phase === ResponsePhase.Complete &&
      this.state.isSubmitting &&
      areAllRecordingsUploaded !== this.props.areAllRecordingsUploaded &&
      areAllRecordingsUploaded
    ) {
      void this.submit()
    } else if (
      phase === ResponsePhase.Complete &&
      phase !== this.props.responseState.phase
    ) {
      let { startTime } = this.state
      if (startTime === null) {
        reportError(
          new TypeError("Completed response without setting `startTime`")
        )
        startTime = performance.now()
      }
      updateResponse({ duration_ms: performance.now() - startTime })
      this.props.cleanupScreenStream()
      if (this.props.areAllRecordingsUploaded) {
        void this.submit()
      } else {
        this.setState({ isSubmitting: true })
      }
    }
  }

  componentDidMount() {
    const { loadUsabilityTest, keepResponseAlive, response } = this.props

    if (isPanelOrdered(response)) {
      // Ordered responses must be kept alive to avoid idle timeouts
      keepResponseAlive()
    }
    if (!isRecruited(response)) {
      // Participant coming from the panel or a third-party panel should be
      // warned before they leave the test because they can't redo it.
      preventUnload()
    }

    void loadUsabilityTest(isPreview(response))
  }

  private handleSkip = async (reason: ParticipantDeletionReason) => {
    this.setState({ isSubmitting: true })
    await this.props.onSkip(reason)
    this.setState({ isSubmitting: false })
  }

  render() {
    const {
      isConnected,
      welcomeCopy,
      language,
      response,
      testBranding,
      isLoaded,
      didLoadFail,
      hasPlayableScreenshot,
      allowedRecordingTypes,
      recordingSetupGuideFinished,
    } = this.props
    const {
      isAbleToRetrySubmit,
      isSkipModalOpen,
      isSubmitting,
      startTime,
      submitError,
    } = this.state
    let content: LayoutAppearanceProps

    const isPanelist = isPanelOrdered(response)

    // Handle deletion reasons before checking `didSubmitFail`. If a response
    // gets submitted after it is deleted the submission fails. Prefer to check
    // the deletion reasons first.
    //
    // TODO: Consider actually returning the deleted response on submission? May
    //       not be necessary.
    if (didLoadFail) {
      content = loadErrorContent()
    } else if (isSubmitting) {
      content = submittingContent()
    } else if (
      isDeleted(response) &&
      // Inactive is handled separately, see also `<TimeoutGracePeriodModal />`
      response.deletion_reason !== ParticipantDeletionReason.Inactive
    ) {
      switch (response.deletion_reason) {
        case AutomaticDeletionReason.Disconnected:
          content = disconnectedErrorContent()
          break
        case AutomaticDeletionReason.TimedOut:
          content = timedOutErrorContent()
          break
        case AutomaticDeletionReason.Malicious:
          content = maliciousErrorContent({ responseId: response.id })
          break
        case AutomaticDeletionReason.TooFast:
          content = tooFastErrorContent({ responseId: response.id })
          break
        case ParticipantDeletionReason.CanceledToStartAnotherResponse:
          content = abandonedErrorContent()
          break
        default:
          content = unhandledDeletionReasonContent()
          // If the user has flagged the test then just continue with test interface.
          if (isParticipantDeletionReason(response.deletion_reason)) {
            content = testContent(this.props)
          } else {
            // Otherwise report this error as unexpected.
            reportError(
              new Error(
                `Unexpected deletion reason: ${response.deletion_reason}`
              ),
              {
                extra: { id: response.id, reason: response.deletion_reason },
              }
            )
          }
          break
      }
    } else if (submitError !== null) {
      content = submitErrorContent({
        message: submitError,
        response,
        retry: isAbleToRetrySubmit ? this.submit : null,
      })
    } else if (startTime === null) {
      content = welcomeContent({
        copy: welcomeCopy,
        isLoaded,
        onStart: this.handleStart,
        branding: testBranding,
        hasPlayableScreenshot,
        allowedRecordingTypes,
        isPanelist,
      })
    } else if (!recordingSetupGuideFinished) {
      content = recordingSetupGuideContent()
    } else {
      content = testContent(this.props)
    }

    // Only show report button to our panelists.
    if (!isPanelist) {
      content.isReportButtonVisible = false
    }

    let bannerNode: ReactNode = null
    if (isPreview(response)) {
      bannerNode = (
        <UsabilityTestPreviewBanner
          setStarted={(started: boolean) =>
            this.setState({ startTime: started ? performance.now() : null })
          }
        />
      )
    } else if (!isConnected) {
      bannerNode = <DisconnectedBanner />
    }

    return (
      <TestBrandingContextProvider branding={testBranding}>
        <SkipUsabilityTestModal
          isOpen={isSkipModalOpen}
          onClose={this.handleCloseSkipModal}
          onSkip={this.handleSkip}
          language={language}
        />

        <UsabilityTestLayout
          bannerChildren={bannerNode}
          onReport={this.handleOpenSkipModal}
          {...content}
        />
      </TestBrandingContextProvider>
    )
  }
}

export const UsabilityTestImpl = connect(null, (dispatch: Dispatch) => ({
  async onSkip(reason: ParticipantDeletionReason) {
    await dispatch(cancelResponse(reason))
    permitUnload()

    // TODO: Should this be used by testers? See also, comment in EditPasswordForm
    const dashboard = ROUTES.DASHBOARD.path

    window.location.href =
      reason === ParticipantDeletionReason.Skipped
        ? dashboard
        : TestInterfaceApi.flagged.path()
    await neverResolve()
  },
}))(Impl)
