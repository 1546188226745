import { Button } from "@chakra-ui/react"
import {
  Layout,
  Main,
  Sidebar,
} from "Components/fixed-sidebar-layout/fixed-sidebar-layout"
import { IndividualResponseDrawer } from "Components/individual-response/IndividualResponseDrawer"
import { DemographicsContextProvider } from "Components/test-results/context/demographics"
import { FilteredResponsesContextProvider } from "Components/test-results/context/filtered-responses"
import { TestResultsFilterStateContextProvider } from "Components/test-results/context/test-result-filter-state"
import { TestResultViewStateProvider } from "Components/test-results/context/test-result-view-state"
import { TestResultsSections } from "Components/test-results/test-results-sections"
import { Dispatch } from "Redux/app-store"
import {
  saveReview,
  saveThirdPartyOrderReview,
} from "Redux/reducers/test-results/action-creators"
import {
  getAllResponses,
  getIsRevising,
  getIsSavingReview,
  getUsabilityTest,
} from "Redux/reducers/test-results/selectors"
import { LocaleProvider } from "Shared/contexts/LocaleContext"
import { AdminUsabilityTest } from "Types"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReviewingBanner } from "./ReviewingBanner"
import { ConfirmReviseReportedResponsesModal } from "./confirm-revise-reported-responses-modal"
import ResponseDurations from "./response-durations/response-durations"

interface OrderResponsesReviewProps {
  isThirdPartyOrder?: boolean
  reportedResponseIds?: number[]
  orderId: number
  revising: boolean
}

export const OrderResponsesReview: React.FC<
  React.PropsWithChildren<OrderResponsesReviewProps>
> = ({ orderId, isThirdPartyOrder, reportedResponseIds }) => {
  const dispatch: Dispatch = useDispatch()

  const isRevising = useSelector(getIsRevising)
  const isSaving = useSelector(getIsSavingReview)

  // NOTE: This is a hack beacuse we're actually violating the state types in
  // the context in which this component is mounted - we change the
  // seiralization to add one extra field. Actually I don't see any reason for
  // this because there's nothing insecure about providing this value to the
  // users, but that's out of scope of my current change.
  const usabilityTest = useSelector(
    getUsabilityTest
  ) as Readonly<AdminUsabilityTest>

  const { open: openConfirmModal } = useModal(
    ConfirmReviseReportedResponsesModal
  )

  const onSaveThirdPartyOrder = async () => {
    await dispatch(saveThirdPartyOrderReview())
  }

  const responses = useSelector(getAllResponses)
  const onSave = async () => {
    if (isThirdPartyOrder) {
      let needConfirmModal = false
      if (isRevising && reportedResponseIds) {
        const countReportedResponses = responses.filter(
          (response) =>
            response.review_status === 0 &&
            reportedResponseIds.includes(response.id)
        ).length

        if (countReportedResponses > 0) {
          openConfirmModal({
            numOfReportedResponses: countReportedResponses,
            handleConfirm: onSaveThirdPartyOrder,
          })
          needConfirmModal = true
        }
      }

      if (!needConfirmModal) {
        await onSaveThirdPartyOrder()
      }
    } else {
      await dispatch(saveReview())
    }
  }

  return (
    <LocaleProvider
      keys={["demographics", "platform", "device_type", "test.tree_test"]}
    >
      <ReviewingBanner
        orderId={orderId}
        isThirdPartyOrder={!!isThirdPartyOrder}
      />
      <TestResultViewStateProvider
        isReviewing
        isThirdPartyOrder={isThirdPartyOrder}
      >
        <TestResultsFilterStateContextProvider>
          <FilteredResponsesContextProvider>
            <Layout>
              <Sidebar>
                <ResponseDurations usabilityTest={usabilityTest} />
                <Button
                  colorScheme="brand.primary"
                  isDisabled={isSaving}
                  onClick={onSave}
                >
                  Submit {isRevising && "revised "}grades
                </Button>
              </Sidebar>
              <Main>
                <div className="TestReview">
                  <TestResultsSections sections={usabilityTest.sections} />
                </div>
              </Main>
              <DemographicsContextProvider>
                <IndividualResponseDrawer />
              </DemographicsContextProvider>
            </Layout>
          </FilteredResponsesContextProvider>
        </TestResultsFilterStateContextProvider>
      </TestResultViewStateProvider>
    </LocaleProvider>
  )
}
