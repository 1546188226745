import { Grid, GridItem, Stack } from "@chakra-ui/react"
import { RecordingPlayer } from "Components/test-recording/RecordingPlayer"
import { useAccountEnabledFeature } from "Hooks/use-account-disabled-feature"
import { useFeatureFlag } from "Hooks/use-feature-flag"
import type { ResponseSection } from "Types"
import { RECORDING_KEY } from "UsabilityHub/views/settings/FeatureAccessPage/FeatureAccessForm"
import React from "react"
import { RecordingErrorView, RecordingProcessingView } from "./RecordingContent"
import { useResponseSectionRecording } from "./useResponseSectionRecording"

export const RecordingsSimplePreview: React.FC<{
  responseSection: ResponseSection
}> = ({ responseSection }) => {
  const recording = useResponseSectionRecording(responseSection)
  const featureFlagEnabled = useFeatureFlag("usability_test_recordings")
  const recordingFeatureEnabled = useAccountEnabledFeature(RECORDING_KEY)

  const [hasRecordingError, setHasRecordingError] = React.useState(false)

  if (!featureFlagEnabled || !recordingFeatureEnabled || recording === null) {
    return
  }

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
      <GridItem w="100%" boxShadow="base" borderRadius="md" key={recording.id}>
        <Stack justify="space-between" w="full">
          <RecordingPlayer
            recording={recording}
            setHasRecordingError={setHasRecordingError}
            bottomBorder
            hideParticipantButton
          />
        </Stack>
      </GridItem>
      <GridItem>
        <RecordingStatus
          status={recording.status}
          hasRecordingError={hasRecordingError}
        />
      </GridItem>
    </Grid>
  )
}

const RecordingStatus: React.FC<{
  status: string
  hasRecordingError: boolean
}> = ({ status, hasRecordingError }) =>
  hasRecordingError || status === "failed" ? (
    <RecordingErrorView
      mediumFonts
      p={4}
      borderRadius="md"
      textAlign="start"
      alignItems="start"
      text="Please try again in a few minutes or reach out to our support team."
    />
  ) : status === "processed" ? null : (
    <RecordingProcessingView textAlign="start" alignItems="start" />
  )
