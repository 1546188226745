import { isNumber } from "lodash"

import { Dispatch, State } from "Redux/app-store"
import { showErrorMessage, showNoticeMessage } from "Redux/reducers/flash"
import { ActionType } from "Redux/reducers/test-results/constants"
import {
  getAllResponses,
  getIsRevising,
  getReviewingOrderId,
} from "Redux/reducers/test-results/selectors"
import { axios, isAxiosErrorWithMessage } from "Services/axios"
import {
  AnswerTag,
  AsyncThunkAction,
  BasicAction,
  OwnerDeletionReason,
  PayloadAction,
  QuestionTag,
  ResultsViewState,
  ReviewStatus,
  Unpersisted,
} from "Types"
import { isPresent } from "Utilities/values"
import { apiUsabilityTests } from "~/api"
import PayoutsApi from "~/api/admin/payoutsApi"
import ThirdPartyOrdersApi from "~/api/admin/thirdPartyOrdersApi"
import QuestionTagsApi from "~/api/questionTagsApi"
import ResponsesApi from "~/api/responsesApi"

import { PresentRawTestResultsState, TestResultsState } from "./reducer"

type InitializeTestResultsAction = PayloadAction<
  ActionType.INITIALIZE_TEST_RESULTS,
  TestResultsState
>
type SetResponseReviewStatusAction = PayloadAction<
  ActionType.UPDATE_ORDER_RESPONSES,
  { responseId: number; reviewStatus: ReviewStatus }
>
type SaveReviewRequestAction =
  BasicAction<ActionType.SAVE_ORDER_RESPONSES_REVIEW_REQUEST>
type SaveReviewSuccessAction =
  BasicAction<ActionType.SAVE_ORDER_RESPONSES_REVIEW_SUCCESS>
type SaveReviewFailureAction =
  BasicAction<ActionType.SAVE_ORDER_RESPONSES_REVIEW_FAILURE>
type DeleteResponseRequestAction = PayloadAction<
  ActionType.DELETE_RESPONSE_REQUEST,
  number
>
type DeleteResponseSuccessAction = PayloadAction<
  ActionType.DELETE_RESPONSE_SUCCESS,
  number
>
type DeleteResponseFailureAction = PayloadAction<
  ActionType.DELETE_RESPONSE_FAILURE,
  number
>

type CreateQuestionTagRequestAction =
  BasicAction<ActionType.CREATE_QUESTION_TAG_REQUEST>
type CreateQuestionTagFailureAction =
  BasicAction<ActionType.CREATE_QUESTION_TAG_FAILURE>
type CreateQuestionTagSuccessAction = PayloadAction<
  ActionType.CREATE_QUESTION_TAG_SUCCESS,
  {
    questionTag: Readonly<QuestionTag>
    answerTags: ReadonlyArray<Readonly<AnswerTag>>
  }
>

type RemoveQuestionTagRequestAction =
  BasicAction<ActionType.REMOVE_QUESTION_TAG_REQUEST>
type RemoveQuestionTagFailureAction =
  BasicAction<ActionType.REMOVE_QUESTION_TAG_FAILURE>
type RemoveQuestionTagSuccessAction = PayloadAction<
  ActionType.REMOVE_QUESTION_TAG_SUCCESS,
  { questionTag: Readonly<QuestionTag> }
>

type TagAnswersRequestAction = BasicAction<ActionType.TAG_ANSWERS_REQUEST>
type TagAnswersFailureAction = BasicAction<ActionType.TAG_ANSWERS_FAILURE>
type TagAnswersSuccessAction = PayloadAction<
  ActionType.TAG_ANSWERS_SUCCESS,
  { answerTags: ReadonlyArray<Readonly<AnswerTag>> }
>

type UntagAnswersRequestAction = BasicAction<ActionType.UNTAG_ANSWERS_REQUEST>
type UntagAnswersFailureAction = BasicAction<ActionType.UNTAG_ANSWERS_FAILURE>
type UntagAnswersSuccessAction = PayloadAction<
  ActionType.UNTAG_ANSWERS_SUCCESS,
  { answerIds: ReadonlyArray<number>; questionTagId: number }
>

type UpdateFigmaSectionRequestAction = PayloadAction<
  ActionType.UPDATE_FIGMA_SECTION_REQUEST,
  {
    sectionId: number
    goalScreenId: string | null
    commonPathNames: { [name: string]: string }
  }
>
type UpdateFigmaSectionFailureAction = PayloadAction<
  ActionType.UPDATE_FIGMA_SECTION_FAILURE,
  {
    sectionId: number
    goalScreenId: string | null
    commonPathNames: { [name: string]: string }
  }
>
type UpdateFigmaSectionSuccessAction =
  BasicAction<ActionType.UPDATE_FIGMA_SECTION_SUCCESS>

type UpdateQuestionTagRequestAction =
  BasicAction<ActionType.UPDATE_QUESTION_TAG_REQUEST>
type UpdateQuestionTagFailureAction =
  BasicAction<ActionType.UPDATE_QUESTION_TAG_FAILURE>
type UpdateQuestionTagSuccessAction = PayloadAction<
  ActionType.UPDATE_QUESTION_TAG_SUCCESS,
  { questionTag: Readonly<QuestionTag> }
>

export type Actions =
  | InitializeTestResultsAction
  | CreateQuestionTagFailureAction
  | CreateQuestionTagRequestAction
  | CreateQuestionTagSuccessAction
  | DeleteResponseFailureAction
  | DeleteResponseRequestAction
  | DeleteResponseSuccessAction
  | RemoveQuestionTagFailureAction
  | RemoveQuestionTagRequestAction
  | RemoveQuestionTagSuccessAction
  | SaveReviewFailureAction
  | SaveReviewRequestAction
  | SaveReviewSuccessAction
  | SetResponseReviewStatusAction
  | TagAnswersFailureAction
  | TagAnswersRequestAction
  | TagAnswersSuccessAction
  | UntagAnswersFailureAction
  | UntagAnswersRequestAction
  | UntagAnswersSuccessAction
  | UpdateFigmaSectionFailureAction
  | UpdateFigmaSectionRequestAction
  | UpdateFigmaSectionSuccessAction
  | UpdateQuestionTagFailureAction
  | UpdateQuestionTagRequestAction
  | UpdateQuestionTagSuccessAction

export const initializeTestResults =
  (rawTestResultsState: PresentRawTestResultsState) => (dispatch: Dispatch) => {
    const selectedGoalScreenIds: { [key: number]: string } = {}
    const commonPathNames: ResultsViewState["commonPathNames"] = []
    rawTestResultsState.usabilityTest?.sections.forEach((section) => {
      if (section.figma_file_flow) {
        if (section.figma_file_flow.goal_node_id) {
          selectedGoalScreenIds[section.id] =
            section.figma_file_flow.goal_node_id
        }

        commonPathNames[section.id] = section.figma_file_flow.common_path_names
      }
    })

    const testResultState: TestResultsState = {
      ...rawTestResultsState,
      resultsViewState: {
        selectedGoalScreenIds,
        commonPathNames,
      },
      isSavingReview: false,
      isTagging: false,
    }

    dispatch({
      type: ActionType.INITIALIZE_TEST_RESULTS,
      payload: testResultState,
    })
  }

export function setResponseReviewStatus(
  responseId: number,
  reviewStatus: ReviewStatus
): SetResponseReviewStatusAction {
  return {
    type: ActionType.UPDATE_ORDER_RESPONSES,
    payload: {
      responseId,
      reviewStatus,
    },
  }
}

export const saveReview =
  (): AsyncThunkAction<State> => async (dispatch, getState) => {
    const orderId = getReviewingOrderId(getState())
    const responses = getAllResponses(getState())
    const isRevising = getIsRevising(getState())
    const data = responses.map((response) => ({
      id: response.id,
      review_status: isPresent(response.review_status)
        ? response.review_status
        : response.automated_review_status,
    }))
    try {
      dispatch<SaveReviewRequestAction>({
        type: ActionType.SAVE_ORDER_RESPONSES_REVIEW_REQUEST,
      })
      const { data: reviewResponse } = await axios.post(
        PayoutsApi.reviewResponses.path({ id: orderId }),
        {
          data,
        }
      )
      const nextOrderId = reviewResponse.next_order_id
      if (isRevising) {
        dispatch(showNoticeMessage("Review revision saved"))
      } else if (isNumber(nextOrderId)) {
        window.location.href = PayoutsApi.order.path({ id: nextOrderId })
      } else {
        alert("No orders found. Redirecting to index page.")
        window.location.href = PayoutsApi.orders.path()
      }
      dispatch<SaveReviewSuccessAction>({
        type: ActionType.SAVE_ORDER_RESPONSES_REVIEW_SUCCESS,
      })
    } catch (error) {
      alert(error.message)
      dispatch<SaveReviewFailureAction>({
        type: ActionType.SAVE_ORDER_RESPONSES_REVIEW_FAILURE,
      })
    }
  }

export const saveThirdPartyOrderReview =
  (): AsyncThunkAction<State> => async (dispatch, getState) => {
    const thirdPartyOrderId = getReviewingOrderId(getState())
    const responses = getAllResponses(getState())
    const isRevising = getIsRevising(getState())
    const data = responses.map((response) => ({
      id: response.id,
      review_status: isPresent(response.review_status)
        ? response.review_status
        : response.automated_review_status,
    }))

    try {
      dispatch<SaveReviewRequestAction>({
        type: ActionType.SAVE_ORDER_RESPONSES_REVIEW_REQUEST,
      })

      await ThirdPartyOrdersApi.saveReviewedResponses({
        params: { id: thirdPartyOrderId },
        data: { data: data, revise: isRevising },
      })

      /** Refresh page to update fetched responses for review.
       * Reviewed response should disappear.
       * More responses should be fetched from the backend
       * if the rest responses are less than the REVIEW_BATCH_SIZE(50)
       *
       * Besides, the flash message from the backend can be shown after reloading.
       */
      window.location.reload()
    } catch (error) {
      alert(error.message)
      dispatch<SaveReviewFailureAction>({
        type: ActionType.SAVE_ORDER_RESPONSES_REVIEW_FAILURE,
      })
    }
  }

interface DeleteResponseDelete {
  message: string
}

export const deleteResponse =
  (responseId: number, reason: OwnerDeletionReason): AsyncThunkAction<State> =>
  async (dispatch) => {
    try {
      dispatch<DeleteResponseRequestAction>({
        type: ActionType.DELETE_RESPONSE_REQUEST,
        payload: responseId,
      })
      const { message } = (
        await axios.delete(ResponsesApi.destroy.path({ id: responseId }), {
          data: { response: { deletion_reason: reason } },
        })
      ).data as DeleteResponseDelete
      dispatch(showNoticeMessage(message))
      dispatch<DeleteResponseSuccessAction>({
        type: ActionType.DELETE_RESPONSE_SUCCESS,
        payload: responseId,
      })
    } catch (error) {
      dispatch<DeleteResponseFailureAction>({
        type: ActionType.DELETE_RESPONSE_FAILURE,
        payload: responseId,
      })
      const message = isAxiosErrorWithMessage(error)
        ? error.response.data.message
        : "An unexpected error occurred!"
      dispatch(showErrorMessage(message))
      throw error
    }
  }

export const createQuestionTag =
  (
    questionTag: Unpersisted<QuestionTag>,
    answerIds: ReadonlyArray<number>
  ): AsyncThunkAction<State> =>
  async (dispatch) => {
    dispatch<CreateQuestionTagRequestAction>({
      type: ActionType.CREATE_QUESTION_TAG_REQUEST,
    })
    try {
      const response = await axios.post(QuestionTagsApi.create.path(), {
        question_tag: questionTag,
        answer_ids: answerIds,
      })
      dispatch<CreateQuestionTagSuccessAction>({
        type: ActionType.CREATE_QUESTION_TAG_SUCCESS,
        payload: {
          questionTag: response.data.question_tag,
          answerTags: response.data.answer_tags,
        },
      })
    } catch (error) {
      dispatch(showErrorMessage(`Creating tag "${questionTag.name}" failed`))
      dispatch<CreateQuestionTagFailureAction>({
        type: ActionType.CREATE_QUESTION_TAG_FAILURE,
      })
      throw error
    }
  }

export const removeQuestionTag =
  (questionTag: QuestionTag): AsyncThunkAction<State> =>
  async (dispatch) => {
    dispatch<RemoveQuestionTagRequestAction>({
      type: ActionType.REMOVE_QUESTION_TAG_REQUEST,
    })
    try {
      await axios.delete(
        QuestionTagsApi.destroy.path({ questionTagId: questionTag.id })
      )
      dispatch<RemoveQuestionTagSuccessAction>({
        type: ActionType.REMOVE_QUESTION_TAG_SUCCESS,
        payload: { questionTag },
      })
      dispatch(showNoticeMessage(`Removed tag "${questionTag.name}"`))
    } catch (error) {
      dispatch(showErrorMessage(`Removing tag "${questionTag.name}" failed`))
      dispatch<RemoveQuestionTagFailureAction>({
        type: ActionType.REMOVE_QUESTION_TAG_FAILURE,
      })
      throw error
    }
  }

export const tagAnswers =
  (
    questionTagId: number,
    answerIds: ReadonlyArray<number>
  ): AsyncThunkAction<State> =>
  async (dispatch) => {
    dispatch<TagAnswersRequestAction>({ type: ActionType.TAG_ANSWERS_REQUEST })
    try {
      const response = await axios.post(
        QuestionTagsApi.createAnswerTags.path({
          question_tag_id: questionTagId,
        }),
        { answer_ids: answerIds }
      )
      dispatch<TagAnswersSuccessAction>({
        type: ActionType.TAG_ANSWERS_SUCCESS,
        payload: {
          answerTags: response.data.answer_tags,
        },
      })
    } catch (error) {
      dispatch(showErrorMessage("Tagging answers failed"))
      dispatch<TagAnswersFailureAction>({
        type: ActionType.TAG_ANSWERS_FAILURE,
      })
      throw error
    }
  }

export const untagAnswers =
  (
    questionTagId: number,
    answerIds: ReadonlyArray<number>
  ): AsyncThunkAction<State> =>
  async (dispatch) => {
    dispatch<UntagAnswersRequestAction>({
      type: ActionType.UNTAG_ANSWERS_REQUEST,
    })
    try {
      await axios.delete(
        QuestionTagsApi.destroyAnswerTags.path({
          question_tag_id: questionTagId,
        }),
        {
          data: { answer_ids: answerIds },
        }
      )
      dispatch<UntagAnswersSuccessAction>({
        type: ActionType.UNTAG_ANSWERS_SUCCESS,
        payload: { answerIds, questionTagId },
      })
    } catch (error) {
      dispatch(showErrorMessage("Untagging answers failed"))
      dispatch<UntagAnswersFailureAction>({
        type: ActionType.UNTAG_ANSWERS_FAILURE,
      })
      throw error
    }
  }

export const setPrototypeGoalScreenId =
  (
    usabilityTestId: number,
    sectionId: number,
    goalScreenId: string | null
  ): AsyncThunkAction<State> =>
  async (dispatch, getState) => {
    const resultsViewState = getState().testResults?.resultsViewState
    if (!resultsViewState) {
      throw Error("No resultsViewState")
    }

    const originalGoalScreenId =
      resultsViewState.selectedGoalScreenIds[sectionId]
    const commonPathNames = resultsViewState.commonPathNames[sectionId]
    await dispatch(
      updateFigmaSection(
        usabilityTestId,
        sectionId,
        goalScreenId,
        commonPathNames,
        () => {
          return {
            goalScreenId: originalGoalScreenId,
            commonPathNames,
          }
        }
      )
    )
  }

export const setCommonPathNames =
  (
    usabilityTestId: number,
    sectionId: number,
    commonPath: string,
    name: string
  ): AsyncThunkAction<State> =>
  async (dispatch, getState) => {
    const resultsViewState = getState().testResults?.resultsViewState
    if (!resultsViewState) {
      throw Error("No resultsViewState")
    }

    const goalScreenId = resultsViewState.selectedGoalScreenIds[sectionId]
    const commonPathNames = resultsViewState.commonPathNames[sectionId]
    const originalName = commonPathNames[commonPath]
    commonPathNames[commonPath] = name

    await dispatch(
      updateFigmaSection(
        usabilityTestId,
        sectionId,
        goalScreenId,
        commonPathNames,
        () => {
          commonPathNames[commonPath] = originalName
          return {
            goalScreenId,
            commonPathNames,
          }
        }
      )
    )
  }

const updateFigmaSection =
  (
    usabilityTestId: number,
    sectionId: number,
    goalScreenId: string | null,
    commonPathNames: { [name: string]: string },
    rollback: () => {
      goalScreenId: string | null
      commonPathNames: { [name: string]: string }
    }
  ): AsyncThunkAction<State> =>
  async (dispatch) => {
    // optimistically propagate the new values
    dispatch<UpdateFigmaSectionRequestAction>({
      type: ActionType.UPDATE_FIGMA_SECTION_REQUEST,
      payload: { sectionId, goalScreenId, commonPathNames },
    })

    try {
      await apiUsabilityTests.figmaSection({
        params: {
          usability_test_id: usabilityTestId,
        },
        data: {
          section_id: sectionId,
          goal_node_id: goalScreenId,
          common_path_names: commonPathNames,
        },
      })
      dispatch<UpdateFigmaSectionSuccessAction>({
        type: ActionType.UPDATE_FIGMA_SECTION_SUCCESS,
      })
    } catch (error) {
      // revert to the original values if the request fails
      const original = rollback()
      dispatch<UpdateFigmaSectionFailureAction>({
        type: ActionType.UPDATE_FIGMA_SECTION_FAILURE,
        payload: {
          sectionId,
          goalScreenId: original.goalScreenId,
          commonPathNames: original.commonPathNames,
        },
      })
      throw error
    }
  }
