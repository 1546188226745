import { UsabilityTestSection, UsabilityTestSectionType } from "Types"
import { DashboardUsabilityTest } from "~/api/generated/usabilityhubSchemas"

export const isExternalStudy = (
  test: { sections: UsabilityTestSection[] } | DashboardUsabilityTest
) => {
  return (
    test &&
    "sections" in test &&
    test.sections.length === 1 &&
    ("type" in test.sections[0]
      ? test.sections[0].type
      : test.sections[0].section_type) ===
      UsabilityTestSectionType.ExternalStudy
  )
}
