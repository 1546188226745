import {
  isTaskComplete,
  isTaskStarted,
  sectionStartTime,
} from "Utilities/response"
import React, { PropsWithChildren, useState } from "react"
import { InnerProps } from "../../props"
import { ExternalStudyActive } from "./ExternalStudyActive"
import { ExternalStudyInstructions } from "./ExternalStudyInstructions"

export const ExternalStudyTask: React.FC<PropsWithChildren<InnerProps>> = ({
  responseSection,
  updateResponseSection,
  usabilityTestSection,
}) => {
  const [clickStartTime, setClickStartTime] = useState<number | null>(null)

  const isStarted = isTaskStarted(responseSection)
  const isComplete = isTaskComplete(responseSection)

  const startTask = () => {
    const startTime = performance.now()

    setClickStartTime(startTime)

    updateResponseSection(usabilityTestSection.id, {
      instructions_duration_ms: startTime - sectionStartTime(responseSection),
    })
  }

  const finishTask = () => {
    const finishTime = performance.now()

    updateResponseSection(usabilityTestSection.id, {
      task_duration_ms: finishTime - (clickStartTime ?? 0),
    })
  }

  if (!isStarted) {
    return <ExternalStudyInstructions handleStart={startTask} />
  }

  if (!isComplete && usabilityTestSection.external_study) {
    return (
      <ExternalStudyActive
        usabilityTestSection={usabilityTestSection}
        handleFinish={finishTask}
      />
    )
  }

  // On task completion it will automatically move to the next section
  return null
}
