import { Box, Flex, HStack, Icon, Text, Tooltip } from "@chakra-ui/react"
import React from "react"

import { getSectionTypeRules } from "Constants/test-section-types"
import { Tag } from "DesignSystem/components"
import { useAccountEnabledFeature } from "Hooks/use-account-disabled-feature"
import { useFeatureFlag } from "Hooks/use-feature-flag"
import { PlaySquareSolidIcon } from "Shared/icons/untitled-ui/PlaySquareSolidIcon"
import { UsabilityTestSectionType } from "Types"
import { AnimatingBadge } from "UsabilityHub/components/AnimatingBadge/AnimatingBadge"
import { RECORDING_KEY } from "UsabilityHub/views/settings/FeatureAccessPage/FeatureAccessForm"

interface AddSectionCardButtonProps {
  sectionType: UsabilityTestSectionType
  onAddSection: (sectionType: UsabilityTestSectionType) => void
}

export const AddSectionCardButton: React.FC<
  React.PropsWithChildren<AddSectionCardButtonProps>
> = ({ sectionType, onAddSection }) => {
  const {
    badgeText,
    colorScheme,
    defaultTitle: title,
    description,
    icon,
    qaId,
    isRecordable,
  } = getSectionTypeRules(sectionType)
  const recordingFeatureFlagEnabled = useFeatureFlag(
    "usability_test_recordings"
  )

  const recordingFeatureEnabled = useAccountEnabledFeature(RECORDING_KEY)
  const isSectionRecordingEnabled =
    isRecordable && recordingFeatureEnabled && recordingFeatureFlagEnabled

  return (
    <HStack
      data-qa={`add-${qaId}-button`}
      as="button"
      type="button" // Prevent form submit
      spacing={4}
      alignItems="flex-start"
      textAlign="left"
      padding={4}
      _hover={{ bg: "gray.100" }}
      _focus={{ bg: "gray.100" }}
      _active={{ bg: "gray.200" }}
      borderRadius="16px"
      border="1px solid"
      borderColor="border.default"
      onClick={() => onAddSection(sectionType)}
    >
      <Icon as={icon} boxSize={8} flexShrink={0} color={colorScheme} />
      <Box flex="1">
        <HStack>
          <Text as="span" textStyle="ds.heading.primary">
            {title}
          </Text>
          {badgeText && (
            <AnimatingBadge colorScheme="pink">{badgeText}</AnimatingBadge>
          )}
        </HStack>
        <Text as="span" textStyle="ds.paragraph.primary" color="ds.text.subtle">
          {description}
        </Text>
        <Flex mt={2}>
          {isSectionRecordingEnabled && (
            <Tooltip
              label="This section supports screen and think-aloud recording"
              aria-label={`${title} sections support recording`}
              placement="top"
              borderRadius={4}
              shouldWrapChildren
            >
              <Tag label="Recordings" leftIcon={PlaySquareSolidIcon} />
            </Tooltip>
          )}
        </Flex>
      </Box>
    </HStack>
  )
}
