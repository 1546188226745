import {
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Radio,
  RadioGroup,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react"
import { ExternalLinkIcon } from "@heroicons/react/outline"
import { CommentThread } from "Components/comment-thread/CommentThread"
import Constants from "Constants/shared.json"
import { Alert } from "DesignSystem/components"
import {
  getFormValue,
  getFormValues,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import { Copy06OutlineIcon } from "Shared/icons/untitled-ui/Copy06OutlineIcon"
import {
  TestCard,
  TestCardHeading,
} from "UsabilityHub/components/TestCard/TestCard"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"
import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Field, WrappedFieldProps, change } from "redux-form"
import { SectionInstructionField } from "../SectionFields/SectionInstructionField"

export const ExternalStudySectionCard: React.FC = () => {
  const dispatch = useDispatch()
  const sectionIndex = useSectionIndexContext()
  const sectionPath = `sections[${sectionIndex}].external_study_attributes`

  const completionCode = useSelector(
    getFormValue(`${sectionPath}.completion_code`)
  )

  useEffect(() => {
    if (!completionCode) {
      const randomCode = Math.random().toString(36).slice(2, 8)
      dispatch(
        change("test-form", `${sectionPath}.completion_code`, randomCode)
      )
    }
  }, [completionCode])

  return (
    <MinimalSectionCard>
      <TestCardHeading>External study</TestCardHeading>

      <Flex direction="column" mt={6} gap={6}>
        <SectionInstructionField
          required
          rows={2}
          label="Instructions"
          placeholder="Instructions go here"
        />

        <Flex direction="column" gap={3}>
          <Heading as="h3">1. What's the URL of your study?</Heading>

          <Field name={`${sectionPath}.url`} component={UrlField} />
        </Flex>

        <Flex direction="column" gap={3}>
          <Heading as="h3">2. Estimated duration</Heading>

          <Text>
            Enter how long you expect participants to spend completing your
            study.
          </Text>

          <Field
            name={`${sectionPath}.estimated_duration_in_minutes`}
            component={EstimatedDurationField}
          />
        </Flex>

        <Flex direction="column" gap={3}>
          <Heading as="h3">3. Linking responses to a Lyssna ID</Heading>

          <Text>
            Choose how to link responses in your research tool to panelists from
            Lyssna.
          </Text>
          <Text>
            This will allow you to review the panelist profile and submit
            feedback on quality for each response on the Lyssna platform.
          </Text>
          <Text>How do you want to link Lyssna panelist IDs?</Text>

          <Field
            name={`${sectionPath}.participation_strategy`}
            component={ParticipationStrategyField}
          />
        </Flex>

        <Flex direction="column" gap={3}>
          <Heading as="h3">4. Communicate a completion code</Heading>

          <Text>
            Choose how you want to communicate a completion code to Lyssna
            panelists.
          </Text>
          <Text>
            This code is required by the panelist to prove that they{"\u2019"}ve
            completed the test in order to get paid, and also allows Lyssna to
            track how long it took for them to complete the test.
          </Text>
          <Text>How do you want to communicate the completion code?</Text>

          <Field
            name={`${sectionPath}.completion_strategy`}
            component={CompletionStrategyField}
          />

          <Field
            component="input"
            type="hidden"
            name={`${sectionPath}.completion_code`}
          />
        </Flex>
      </Flex>
    </MinimalSectionCard>
  )
}

// Similar to SectionCard, but without a header and other controls like test logic, deletion, etc.
const MinimalSectionCard: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const sectionIndex = useSectionIndexContext()
  const { section } = useSectionContext()
  const commentableEntity = useMemo(
    () =>
      ({
        entityContext: "test_builder",
        entityType: "usability_test_section",
        entityId: section.id ? String(section.id) : section._clientId,
      }) as const,
    [section.id, section._clientId]
  )

  return (
    <TestCard
      id={`section_${sectionIndex}`}
      commentableEntity={commentableEntity}
      position="relative"
    >
      <CommentThread
        entity={commentableEntity}
        isEntityPersisted={section.id !== null}
        offsetX={22}
        offsetY={16}
      />

      {children}
    </TestCard>
  )
}

const UrlField: React.FC<WrappedFieldProps> = ({ input }) => {
  const { readOnly } = useUsabilityTestUserActivityContext()

  const formValues = useSelector(getFormValues)

  const externalStudy = formValues.sections[0].external_study_attributes
  const participationStrategy = externalStudy?.participation_strategy

  return (
    <InputGroup>
      <InputLeftAddon>
        <Icon as={ExternalLinkIcon} color="gray.500" />
      </InputLeftAddon>
      <Input
        isReadOnly={readOnly}
        placeholder="https://example.com"
        {...input}
      />
      {participationStrategy === "query_parameters" && (
        <InputRightAddon>
          {input.value.includes("?") ? "&" : "?"}fake_query_parameter=fake_value
        </InputRightAddon>
      )}
    </InputGroup>
  )
}

const EstimatedDurationField: React.FC<WrappedFieldProps> = ({ input }) => {
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <InputGroup>
      <Input type="number" maxW="200px" isReadOnly={readOnly} {...input} />
      <InputRightAddon>minutes</InputRightAddon>
    </InputGroup>
  )
}

const ParticipationStrategyField: React.FC<WrappedFieldProps> = ({ input }) => {
  return (
    <RadioGroup {...input}>
      <Flex direction="column" gap={3} ps={2}>
        <Radio name="participation_strategy" value="query_parameters">
          I{"\u2019"}ll use URL parameters
        </Radio>

        {input.value === "query_parameters" && (
          <Alert
            status="info"
            description="Set up your study to automatically send participants to the provided URL. This is the ideal experience, as we can capture the completion code in the URL, and participants are not required to take additional action."
          />
        )}

        <Radio name="participation_strategy" value="copy_paste">
          I{"\u2019"}ll add a question in my study
        </Radio>

        {input.value === "copy_paste" && (
          <Alert
            status="info"
            description={
              <>
                <Text>
                  Please add a new question at the beginninig of your survey
                  asking participants for their unique Participant ID.
                </Text>

                <Text>Suggested question: What is your participant ID?</Text>
              </>
            }
          />
        )}
      </Flex>
    </RadioGroup>
  )
}

const CompletionStrategyField: React.FC<WrappedFieldProps> = ({ input }) => {
  const toast = useToast()
  const formValues = useSelector(getFormValues)

  const usabilityTestId = formValues.unique_id
  const externalStudy = formValues.sections[0].external_study_attributes
  const completionCode = externalStudy?.completion_code ?? "SAVEFIRST"

  const redirectURL = `${Constants.PANELIST_EXTERNAL_STUDY_REDIRECT_URL_PREFIX}/${usabilityTestId}/${completionCode}`

  const copyCompletionCodeToClipboard = async () => {
    await navigator.clipboard.writeText(completionCode)

    toast({
      title: "Completion code copied to clipboard",
      status: "success",
    })
  }

  const copyRedirectURLToClipboard = async () => {
    await navigator.clipboard.writeText(redirectURL)

    toast({
      title: "Redirect URL copied to clipboard",
      status: "success",
    })
  }

  return (
    <RadioGroup {...input}>
      <Flex direction="column" gap={3} ps={2}>
        <Radio name="participation_strategy" value="redirect_url">
          I{"\u2019"}ll use a redirect URL (with a completion code embedded)
        </Radio>

        {input.value === "redirect_url" && (
          <>
            <Alert
              status="info"
              description="Set up your study to automatically send participants to the provided URL. This is the ideal experience, as we can capture the completion code in the URL, and participants are not required to take additional action."
            />
            <InputGroup>
              <InputLeftAddon>Redirect URL</InputLeftAddon>
              <Flex
                align="center"
                borderTopWidth={1}
                borderBottomWidth={1}
                px={3}
              >
                <Text>{redirectURL}</Text>
              </Flex>
              <InputRightAddon>
                <Tooltip hasArrow placement="top" label="Copy to clipboard">
                  {/* Having this as an IconButton breaks redux-form in a weird way */}
                  <Icon
                    as={Copy06OutlineIcon}
                    aria-label="Copy redirect URL to clipboard"
                    onClick={copyRedirectURLToClipboard}
                    cursor="pointer"
                  />
                </Tooltip>
              </InputRightAddon>
            </InputGroup>
          </>
        )}

        <Radio name="participation_strategy" value="prompt">
          I{"\u2019"}ll provide the completion code in my study
        </Radio>

        {input.value === "prompt" && (
          <>
            <Alert
              status="info"
              description="Please add a completion code to the last page that a panelist will see once they’ve completed the study. The panelist will copy and paste this code back into the Lyssna platform in order to prove completion and be paid for their time."
            />

            <InputGroup>
              <InputLeftAddon>Completion code</InputLeftAddon>

              <Flex
                align="center"
                borderTopWidth={1}
                borderBottomWidth={1}
                px={3}
              >
                <Text maxW="200px">{completionCode}</Text>
              </Flex>

              <InputRightAddon>
                <Tooltip hasArrow placement="top" label="Copy to clipboard">
                  {/* Having this as an IconButton breaks redux-form in a weird way */}
                  <Icon
                    as={Copy06OutlineIcon}
                    aria-label="Copy completion code to clipboard"
                    onClick={copyCompletionCodeToClipboard}
                    cursor="pointer"
                  />
                </Tooltip>
              </InputRightAddon>
            </InputGroup>
          </>
        )}
      </Flex>
    </RadioGroup>
  )
}
