import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { UserGroupIcon, UserIcon } from "@heroicons/react/solid"
import React, { useState } from "react"
import { useSelector } from "react-redux"

import { getAggregatedScreenResultsByFigmaImages } from "Components/test-results/utils/screen-results/aggregate-screen-results/get-aggregated-screen-results-by-figma-images"
import { getScreenResultsFromTasks } from "Components/test-results/utils/screen-results/get-screen-results-from-tasks"
import { getCommonPathsCount } from "Components/test-results/utils/task-paths/get-common-paths/get-common-paths-count"
import {
  getFigmaFileVersionById,
  getSelectedGoalNodeForSection,
} from "Redux/reducers/test-results/selectors"
import { useSectionContext } from "UsabilityHub/contexts"

import { useAccountEnabledFeature } from "Hooks/use-account-disabled-feature"
import { PlaySquareSolidIcon } from "Shared/icons/untitled-ui/PlaySquareSolidIcon"
import { RECORDING_KEY } from "UsabilityHub/views/settings/FeatureAccessPage/FeatureAccessForm"
import { RecordingsTab } from "../Recordings/RecordingsTab"
import { useSectionRecordings } from "../Recordings/useSectionRecordings"
import { CommonPathsList } from "./PathsSummaries/CommonPathsList/CommonPathsList"
import { IndividualPathsList } from "./PathsSummaries/IndividualPathsList/IndividualPathsList"
import { ScreenResults } from "./ScreenResults/ScreenResultDetails"
import { useFilteredTasks } from "./hooks/use-filtered-tasks"
import { useTasks } from "./hooks/use-tasks"

/**
 * At the top of the prototype results, you can see a list of screens and select one to display a
 * more detailed view of that screen. The list is node based, which means each node is only
 * displayed once, and the data it is displaying is an aggregate of all results that happened on
 * that node. The order in which individual results happened are not considered in this view. Node
 * results are based on multiple `ResponseSectionFigmaTask`, but aggregate the information for each
 * node.
 */
export const PrototypeTaskResults: React.FC = () => {
  const { section } = useSectionContext()
  const allTasks = useTasks()
  const filteredTasks = useFilteredTasks()

  const [accordionExpanded, setAccordionExpanded] = useState(false)

  const figmaFileFlow = section.figma_file_flow
  if (!figmaFileFlow) {
    throw new TypeError(`Section with ID ${section.id} has no FigmaFileFlow`)
  }
  const ffvId = figmaFileFlow.figma_file_version_id
  // While actioning some performance issues we lost the ordering of screens in
  // a FigmaFileVersion. This is a quick fix to at least get the start screen
  // to the beginning of the list.
  //
  // TODO: Re-expose FigmaFileVersion node_ids to get proper ordering back.
  const figmaImages = useSelector(getFigmaFileVersionById(ffvId)).images.reduce(
    (acc, element) => {
      if (element.figma_node_id === figmaFileFlow.start_node_id) {
        return [element, ...acc]
      }
      return [...acc, element]
    },
    []
  )

  const goalNodeId = useSelector(getSelectedGoalNodeForSection(section.id))

  const commonPathsCount = getCommonPathsCount(allTasks)
  const individualPathsCount = filteredTasks.length

  const screenResults = getScreenResultsFromTasks(filteredTasks, figmaImages)
  const aggregatedScreenResults = getAggregatedScreenResultsByFigmaImages(
    figmaImages,
    screenResults
  )

  const recordings = useSectionRecordings()
  const recordingFeatureEnabled = useAccountEnabledFeature(RECORDING_KEY)
  const showRecordingsTab = recordings.length > 0 && recordingFeatureEnabled
  const accordianTextSuffix = showRecordingsTab
    ? "paths, clicks and recordings"
    : "paths and clicks"

  return (
    <Stack spacing={12}>
      {figmaImages.length === 0 ? (
        <Box rounded="md" borderWidth={1} borderColor="gray.200" p={4}>
          <Text>Images processing&hellip;</Text>
        </Box>
      ) : (
        <ScreenResults
          showDetailsBar
          screenResults={aggregatedScreenResults}
          totalNumberOfParticipants={filteredTasks.length}
        />
      )}
      <Accordion
        allowToggle
        onChange={(expandedIndex) => {
          setAccordionExpanded(expandedIndex === 0)
        }}
      >
        <AccordionItem border="none">
          <AccordionButton
            ps={0}
            _hover={{ backgroundColor: "transparent" }}
            _focus={{ boxShadow: "none" }}
            fontSize="md"
            fontWeight="semibold"
            textColor="brand.primary.500"
            data-qa="prototype-paths-toggle-button"
          >
            {accordionExpanded
              ? `Hide ${accordianTextSuffix}`
              : `Show ${accordianTextSuffix}`}
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Tabs isLazy lazyBehavior="keepMounted">
              <TabList>
                <Tooltip
                  hasArrow
                  placement="top"
                  label="Identical paths taken by more than one participant"
                >
                  <Tab data-qa="common-paths-tab">
                    <Icon as={UserGroupIcon} mr={2} />
                    <Text>
                      Common paths <small>{commonPathsCount}</small>
                    </Text>
                  </Tab>
                </Tooltip>
                <Tooltip
                  hasArrow
                  placement="top"
                  label="Paths taken by individual participants"
                >
                  <Tab data-qa="individual-paths-tab">
                    <Icon as={UserIcon} mr={2} />
                    <Text>
                      Individual paths <small>{individualPathsCount}</small>
                    </Text>
                  </Tab>
                </Tooltip>
                {showRecordingsTab && (
                  <Tab data-qa="recording-tab">
                    <Icon as={PlaySquareSolidIcon} mr={2} />
                    <Text>
                      Recordings <small>{recordings.length}</small>
                    </Text>
                  </Tab>
                )}
              </TabList>
              {/* TODO: 6751 Condense all our custom-padded tabs panels into a reusable "section tab panel" */}
              <TabPanels>
                <TabPanel p={0} pt={6}>
                  <CommonPathsList
                    filteredTasks={filteredTasks}
                    ffvId={ffvId}
                    goalNodeId={goalNodeId}
                    testId={section.usability_test_id}
                    sectionId={section.id}
                  />
                </TabPanel>
                <TabPanel p={0} pt={6}>
                  <IndividualPathsList
                    tasks={filteredTasks}
                    goalNodeId={goalNodeId}
                  />
                </TabPanel>
                {showRecordingsTab && (
                  <TabPanel p={0} pt={6}>
                    <RecordingsTab />
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  )
}
