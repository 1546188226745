import { Card, Flex, ResponsiveValue } from "@chakra-ui/react"
import React, { useState } from "react"
import { useNavigate } from "react-router"

import { ModeratedStudyListEntry } from "~/api/generated/usabilityhubSchemas"

import { Tag } from "DesignSystem/components"
import { Users01SolidIcon } from "Shared/icons/untitled-ui/Users01SolidIcon"
import Created from "../../../../components/Created"
import ContextMenu from "./ContextMenu"
import RecruitButton from "./RecruitButton"
import { Stats } from "./Stats"
import StudyName from "./StudyName"

type ModeratedStudyCardProps = {
  study: ModeratedStudyListEntry
}

type GridDefinition = ResponsiveValue<string>

const grid: GridDefinition = {
  base: `
    "name menu" auto
    "recruit recruit" auto
    "stats created" auto
    / 1fr auto
  `,
  md: `
    "name name menu" auto
    "stats recruit created" auto / 1fr auto auto
  `,
  lg: `
    "name stats recruit created menu" auto / 1fr 12rem auto auto 2.75rem
  `,
} as const

const ModeratedStudyCard: React.FC<ModeratedStudyCardProps> = ({ study }) => {
  const [renaming, setRenaming] = useState(false)

  const isInteractable = study.archived_at === null
  const linkTo = (path: string) => `/interviews/${study.id}/${path}`

  const navigate = useNavigate()

  const cardClicked = (e: React.MouseEvent<HTMLElement>) => {
    const el = e.target as HTMLElement
    if (el.closest('button, a, [role="button"], input')) return

    e.preventDefault()
    navigate(linkTo("sessions"))
  }

  return (
    <Card
      variant="outline"
      display="grid"
      minH="88px"
      rounded="16px"
      shadow="ds.raised"
      color="ds.text.default"
      py={6}
      ps={[4, null, 8]}
      pe={[2, null, 4]}
      columnGap={{ base: 2, sm: 4 }}
      rowGap={2}
      alignItems="center"
      justifyContent="start"
      sx={{
        grid,
        '& [data-hover-only="true"]': {
          opacity: 0,
        },
      }}
      borderWidth={1}
      borderColor="transparent"
      _hover={{
        borderColor: isInteractable ? "ds.border.hovered" : "transparent",
      }}
      onClick={isInteractable ? cardClicked : undefined}
      cursor={isInteractable ? "pointer" : undefined}
    >
      <Flex flexDirection="column" gap={2} alignItems="start">
        <Tag label="Interview" leftIcon={Users01SolidIcon} colorScheme="cyan" />
        <StudyName
          study={study}
          renaming={renaming}
          setRenaming={setRenaming}
          linkTo={linkTo}
        />
      </Flex>
      {study.archived_at === null ? (
        <Flex gridArea="recruit">
          <RecruitButton study={study} linkTo={linkTo} />
        </Flex>
      ) : null}
      <Flex gridArea="stats" gap={2} px={{ base: 0, lg: 6 }}>
        <Stats
          bookingCounts={study.booking_counts}
          applicantCounts={study.applicant_counts}
        />
      </Flex>
      <Flex gridArea="created" gap={2} pe={[2, null, null, 0]}>
        <Created
          createdAt={study.created_at}
          name={study.creator?.name ?? "Deleted user"}
          email={study.creator?.email}
          avatarUrl={study.creator?.avatar_url ?? ""}
        />
      </Flex>
      <Flex
        gridArea="menu"
        pe={[0, null, null, 2]}
        alignItems="center"
        alignSelf={["flex-start", null, null, "auto"]}
        transform={["translateY(-4px)", null, null, "initial"]}
        justifyContent="end"
      >
        <ContextMenu
          study={study}
          onRename={() => setRenaming(true)}
          linkTo={linkTo}
        />
      </Flex>
    </Card>
  )
}

export default ModeratedStudyCard
