import { Checkbox, Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react"
import { ThemedButton } from "Components/button/themed-button"
import React from "react"

type Props = {
  handleStart: () => void
}

export const ExternalStudyInstructions: React.FC<Props> = ({ handleStart }) => {
  const [agreedToTerms, setAgreedToTerms] = React.useState(false)

  return (
    <Flex
      direction="column"
      maxW="800px"
      h="fit-content"
      bg="white"
      rounded="16px"
      mx="auto"
      mt={6}
      p={4}
      gap={4}
    >
      <Text>Hello there!</Text>

      <Text>You’ve been invited to participate in an external study. </Text>

      <Text fontWeight="bold">
        This means that you will complete a study on a website outside of the
        UserCrowd platform.
      </Text>

      {/* TODO: this depends on the participation and completion strategies */}
      <UnorderedList>
        <ListItem>
          At the start of the test, you will be asked to input your UserCrowd
          ID.{" "}
        </ListItem>
        <ListItem>
          At the end of the test, you will be given a completion code to submit.
        </ListItem>
      </UnorderedList>

      <Text fontWeight="bold">DO NOT CLOSE THIS TAB</Text>

      <Flex direction="column" bg="gray.200" rounded="8px" p={4}>
        <Text mb={4}>
          To protect your privacy and security, please do NOT submit any of the
          following information during the test:
        </Text>

        <UnorderedList>
          <ListItem>Personally identifiable information (PII)</ListItem>
          <ListItem>Login credentials for any personal accounts</ListItem>
          <ListItem>
            Sensitive personal data including sensitive medical, health or
            biometric data
          </ListItem>
          <ListItem>
            Financial information such as credit card details and online payment
            login credentials
          </ListItem>
        </UnorderedList>
      </Flex>

      <Checkbox
        isChecked={agreedToTerms}
        onChange={() => setAgreedToTerms((v) => !v)}
      >
        I understand that all personal data shown on-screen and data I enter
        will be viewable by the test creator.
      </Checkbox>

      <ThemedButton
        w="fit-content"
        isDisabled={!agreedToTerms}
        onClick={handleStart}
      >
        Accept and continue to external study
      </ThemedButton>
    </Flex>
  )
}
