import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Stack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React, { FC } from "react"
import { useSelector } from "react-redux"
import SettingsApi from "~/api/settingsApi"

import { SubmitButton } from "Components/button/submit-button"
import { RailsForm } from "Components/form/form"
import {
  canExportCsv,
  getHiddenResponseCount,
  getResponsesCount,
  getResponsesLimit,
  getUsabilityTest,
} from "Redux/reducers/test-results/selectors"
import { Response, UsabilityTest } from "Types"
import { PlanDrawerTrigger } from "UsabilityHub/components/PlanChanger/plan-drawer"
import { UNLIMITED_VISIBLE_RESPONSES_FEATURE } from "UsabilityHub/components/RecruitmentLink/enabledLinkableFeaturesAccountLacks"
import { TestCard } from "UsabilityHub/components/TestCard/TestCard"
import UsabilityTestsApi from "~/api/usabilityTestsApi"

import { useIsSharedTestResults } from "../context/shared-test-results"
import { useFilteredResponses } from "../hooks/use-filtered-responses"

import { ProTrialIcon } from "Components/trial-widget/ProTrialIcon"
import { useShowProTrialWidget } from "Utilities/account"
import { ShareTestResultsModal } from "../test-results-summary/ShareTestResultsModal"

export const LegacyTestResultsSummary: React.FC = () => {
  const usabilityTest = useSelector(getUsabilityTest)

  const filteredResponses = useFilteredResponses() as Response[]

  const allResponsesCount = useSelector(getResponsesCount)
  const responsesLimitCount = useSelector(getResponsesLimit)
  const hiddenResponseCount = useSelector(getHiddenResponseCount)
  const accountCanExportCsv = useSelector(canExportCsv)

  return (
    <ResultsSummaryUI
      usabilityTest={usabilityTest}
      filteredResponses={filteredResponses}
      allResponsesCount={allResponsesCount}
      responsesLimitCount={responsesLimitCount}
      hiddenResponseCount={hiddenResponseCount}
      accountCanExportCsv={accountCanExportCsv}
    />
  )
}

type ResultsSummaryProps = {
  filteredResponses: Response[]
  allResponsesCount: number
  responsesLimitCount: number
  hiddenResponseCount: number
  usabilityTest: UsabilityTest
  accountCanExportCsv: boolean
}

const ResultsSummaryUI: FC<React.PropsWithChildren<ResultsSummaryProps>> = ({
  usabilityTest,
  filteredResponses,
  allResponsesCount,
  responsesLimitCount,
  hiddenResponseCount,
  accountCanExportCsv,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isShared = useIsSharedTestResults()
  const showProTrialWidget = isShared ? false : useShowProTrialWidget()

  const usabilityTestId = usabilityTest.id
  const usabilityTestUniqueId = usabilityTest.unique_id
  const usabilityTestPrivateId = usabilityTest.private_id

  const filteredResponseIds = filteredResponses.map((response) => response.id)

  const filteredResponsesCount = filteredResponses.length
  const responseCap = Math.min(allResponsesCount, responsesLimitCount)
  const isFiltered = filteredResponsesCount < responseCap

  const PaidFeatureMessage: React.FC<React.PropsWithChildren<unknown>> = () => (
    <Text>
      CSV exports are{" "}
      <Link color="teal.500" href={SettingsApi.billing.path()}>
        available on paid plans
      </Link>
    </Text>
  )

  const proTrialIconIsAttractive = !!(
    showProTrialWidget || !accountCanExportCsv
  )
  const ExportButtonProTrialIcon: React.FC = () => (
    <ProTrialIcon
      isAttractive={proTrialIconIsAttractive}
      attractiveColor="white"
      defaultColor="brand.primary.700"
    />
  )

  return (
    <Stack spacing={8}>
      {allResponsesCount >= responsesLimitCount && (
        <Alert status="warning" alignItems="flex-start">
          <AlertIcon />
          <Flex flexWrap="wrap">
            <AlertTitle flexGrow={1} mb={2}>
              We can{"\u2019"}t display all the results for this test
            </AlertTitle>
            <AlertDescription>
              <Stack spacing={3}>
                <Text>
                  You{"\u2019"}ve collected {allResponsesCount} responses for
                  this test, but we{"\u2019"}re only able to show the first{" "}
                  {responsesLimitCount}. You might be able to spot some patterns
                  here but we suggest you download the full export as CSV to
                  conduct a full analysis.
                </Text>
                <RailsForm
                  method="POST"
                  action={UsabilityTestsApi.exportResults.path({
                    id: usabilityTestUniqueId,
                    privateId: usabilityTestPrivateId,
                  })}
                >
                  {accountCanExportCsv ? (
                    <SubmitButton
                      colorScheme="gray"
                      variant="outline"
                      rightIcon={<ExportButtonProTrialIcon />}
                    >
                      Export all {allResponsesCount} results as CSV
                    </SubmitButton>
                  ) : (
                    <Popover placement="top-start" trigger="hover">
                      <PopoverTrigger>
                        <Box>
                          <SubmitButton
                            colorScheme="gray"
                            variant="outline"
                            rightIcon={<ExportButtonProTrialIcon />}
                            isDisabled
                          >
                            Export all {allResponsesCount} results as CSV
                          </SubmitButton>
                        </Box>
                      </PopoverTrigger>
                      <PopoverContent boxShadow="base">
                        <PopoverArrow />
                        <PopoverBody>
                          <PaidFeatureMessage />
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  )}
                </RailsForm>
              </Stack>
            </AlertDescription>
          </Flex>
        </Alert>
      )}
      <TestCard id="results-summary">
        <HStack>
          <StatGroup flexGrow={1} flexWrap="nowrap" whiteSpace="nowrap">
            <Stat>
              <StatNumber>{allResponsesCount + hiddenResponseCount}</StatNumber>
              <StatLabel>Total participants</StatLabel>
            </Stat>
            <Stat>
              <StatNumber>
                {filteredResponsesCount}
                {isFiltered && ` of ${responseCap}`}
              </StatNumber>
              <StatLabel>
                Responses shown
                {isFiltered && ` (filtered)`}
              </StatLabel>
            </Stat>
          </StatGroup>
          <Spacer />
          <HStack spacing={5}>
            <Button onClick={onOpen}>Share</Button>
            <RailsForm
              action={UsabilityTestsApi.exportResults.path({
                id: usabilityTestUniqueId,
                privateId: usabilityTestPrivateId,
              })}
              method="POST"
            >
              {filteredResponseIds.map((id) => (
                <input
                  key={id}
                  type="hidden"
                  name="response_ids[]"
                  value={id}
                />
              ))}
              {accountCanExportCsv ? (
                <SubmitButton rightIcon={<ExportButtonProTrialIcon />}>
                  Export {filteredResponsesCount} results as CSV
                </SubmitButton>
              ) : (
                <Popover placement="top" trigger="hover">
                  <PopoverTrigger>
                    <Box>
                      <SubmitButton
                        rightIcon={<ExportButtonProTrialIcon />}
                        isDisabled
                      >
                        Export {filteredResponsesCount} results as CSV
                      </SubmitButton>
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent boxShadow="base">
                    <PopoverArrow />
                    <PopoverBody>
                      <PaidFeatureMessage />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
            </RailsForm>
          </HStack>
        </HStack>

        {!isShared && hiddenResponseCount > 0 && (
          <Alert mt={4} status="info" variant="withButton">
            <AlertIcon />
            <AlertTitle>Some responses are hidden</AlertTitle>
            <AlertDescription>
              <Box>
                On the Free plan you can collect as many responses as you like,
                but only the first 15 collected via the recruitment link will be
                visible. To view the other {hiddenResponseCount} responses on
                this test you{"\u2019"}ll need to{" "}
                <PlanDrawerTrigger
                  as={Link}
                  variant="unstyled"
                  verticalAlign="inherit"
                  userSelect="initial"
                  color="brand.primary.500"
                  textDecoration="none"
                  source="test_results_response_limit_banner"
                  requestedFeatures={[UNLIMITED_VISIBLE_RESPONSES_FEATURE]}
                >
                  upgrade to a paid plan
                </PlanDrawerTrigger>
                .
              </Box>
            </AlertDescription>
            <PlanDrawerTrigger
              source="test_results_response_limit_banner"
              requestedFeatures={[UNLIMITED_VISIBLE_RESPONSES_FEATURE]}
              colorScheme="brand.primary"
            >
              See all plans
            </PlanDrawerTrigger>
          </Alert>
        )}
      </TestCard>

      {isOpen && (
        // Avoid a crash on /admin where the test doesn't exist in the store
        // by only rendering the modal if it's specifically "open"
        <ShareTestResultsModal
          isOpen={isOpen}
          onClose={onClose}
          usabilityTestId={usabilityTestId}
        />
      )}
    </Stack>
  )
}
