import React from "react"

import { getEnvState } from "JavaScripts/state"

interface FigmaEmbedProps {
  prototypeUrl: string
}

export const FigmaEmbed: React.FC<React.PropsWithChildren<FigmaEmbedProps>> = ({
  prototypeUrl,
}) => {
  const queryString = new URLSearchParams({
    embed_host: "usabilityhub",
    url: prototypeUrl,
  }).toString()

  // https://www.figma.com/developers/embed
  const embedUrl = `${getEnvState().FIGMA_DOMAIN}/embed?${queryString}`
  return (
    <iframe
      title="Figma content"
      width="100%"
      height="100%"
      id="iframe"
      data-testid="figma-iframe-embed"
      src={embedUrl}
      allowFullScreen
    />
  )
}
