import { Box, Flex } from "@chakra-ui/react"
import { ParticipantIcon } from "Components/ParticipantIcon"
import { TestResultViewState } from "Components/test-results/context/test-result-view-state"
import { EventTime } from "Components/time/event-time"
import { PreciseDuration } from "Components/time/precise-duration"
import { getUsabilityTest } from "Redux/reducers/test-results/selectors"
import { SectionIndexProvider, SectionProvider } from "UsabilityHub/contexts"
import { useFlatLocations } from "UsabilityHub/hooks/useFlatLocations"
import { isExternalStudy } from "UsabilityHub/utils/isExternalStudy"
import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { GetTestResultsIndividualResponseResponse } from "~/api/generated/usabilityhub-components"
import { IndividualScreenerResponse } from "./IndividualScreenerResponse"
import { ResponseOverview } from "./ResponseOverview"
import { IndividualSectionResult } from "./individual-section-result/individual-section-result"

interface IndividualResponseProps {
  response: GetTestResultsIndividualResponseResponse
}

export const IndividualResponse: React.FC<
  React.PropsWithChildren<IndividualResponseProps>
> = ({ response }) => {
  const allLocations = useFlatLocations("usability_tests")
  const usabilityTest = useSelector(getUsabilityTest)
  const { isReviewing } = useContext(TestResultViewState)

  const responseLocation = response.response_demographic_profile?.location
  const locationData =
    responseLocation && allLocations[responseLocation.type][responseLocation.id]

  // No useful information in the sections for external studies so hide the whole block
  const showSections = !isExternalStudy(usabilityTest)

  return (
    <article>
      <Flex p={4}>
        <ParticipantIcon country={locationData?.countryCode ?? null} />
        <Box flexGrow={1}>
          <Box
            color="gray.700"
            marginBottom={2}
            fontSize="md"
            fontWeight="medium"
          >
            <EventTime date={response.submitted_at!} />
          </Box>
          {!isReviewing && (
            <Box fontSize="sm" color="gray.500">
              Completed in <PreciseDuration ms={response.duration_ms} />
            </Box>
          )}
        </Box>
      </Flex>

      <IndividualResultsDivider />

      <ResponseOverview response={response} />

      {/* If there is screener information with the response, show that before the section answers */}
      {response.screener_response && (
        <>
          <IndividualResultsDivider />

          <IndividualScreenerResponse
            screenerResponse={response.screener_response}
            p={8}
          />
        </>
      )}

      {showSections &&
        usabilityTest.sections.map((section) => {
          return (
            <SectionProvider key={section.id} value={{ section }}>
              <SectionIndexProvider value={section.position}>
                <IndividualResultsDivider />
                <IndividualSectionResult
                  key={section.id}
                  responseId={response.id}
                />
              </SectionIndexProvider>
            </SectionProvider>
          )
        })}
    </article>
  )
}

const IndividualResultsDivider = () => <Box height="1px" bg="gray.200" />
