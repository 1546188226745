import { Box, Button, Center, Flex, Heading, Text } from "@chakra-ui/react"
import {
  SkipUsabilityTestForm,
  SkipUsabilityTestModal,
} from "Components/skip-usability-test-modal/skip-usability-test-modal"
import { State } from "Redux/app-store"
import { getCurrentResponse } from "Redux/reducers/current-response/selectors"
import { axios } from "Services/axios"
import { ParticipantDeletionReason, ParticipantUsabilityTest } from "Types"
import { ROUTES } from "UserCrowd/views/routes"
import React, { ComponentProps, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useCheckExternalStudyCompletionCode } from "~/api/generated/usabilityhub-components"
import responsesApi from "~/api/responsesApi"
import testInterfaceApi from "~/api/testInterfaceApi"
import { OuterProps } from "../../props"
import { DocumentPictureInPicture } from "./DocumentPictureInPicture"
import { ExternalStudyControlPanel } from "./ExternalStudyControlPanel"

type Props = {
  usabilityTestSection: OuterProps["usabilityTestSection"]
  handleFinish: () => void
}

const ENGLISH = {
  code: "en",
  display_string: "English",
  english_name: "English",
  is_supported: true,
  local_name: "English",
}

export const ExternalStudyActive: React.FC<Props> = ({
  usabilityTestSection,
  handleFinish,
}) => {
  const language = ENGLISH // TODO: One day, unhardcode this

  const responseId = useSelector(getCurrentResponse)?.id
  const usabilityTest = useSelector<State>(
    (state) => state.participantUsabilityTest
  ) as Readonly<ParticipantUsabilityTest>

  const [validCode, setValidCode] = useState(false)
  const { mutate } = useCheckExternalStudyCompletionCode({
    onSuccess: () => {
      setValidCode(true)
    },
    onError: () => {
      // TODO: Some kind of feedback here
    },
  })

  const checkCode = (code: string) => {
    mutate({
      body: {
        usability_test_id: usabilityTest.id,
        completion_code: code,
      },
    })
  }

  const [isReportModalOpen, setIsReportModalOpen] = useState(false)
  const [forceClosePip, setForceClosePip] = useState(false)
  const [showFullPageReportForm, setShowFullPageReportForm] = useState(false)
  const taskTab = useRef<Window | null>(null)
  const [pipIsOpen, setPipIsOpen] = useState(false)
  const [pipKey, setPipKey] = useState(0)

  const openTaskTab = () => {
    if (!usabilityTestSection.external_study) {
      throw new Error("External study not found")
    }

    const taskWindow = window.open(
      usabilityTestSection.external_study.url,
      "externalStudyWindow"
    )

    taskTab.current = taskWindow
  }

  useEffect(() => {
    openTaskTab()
  }, [])

  useEffect(() => {
    const bc = new BroadcastChannel("external_study_post_redirect")
    bc.onmessage = (event) => {
      if (event.data === "Redirect") {
        handleEndTask()
      }
    }
  }, [])

  const handleEndTask = () => {
    taskTab.current?.close()
    setForceClosePip(true)
    handleFinish()
  }

  const handleSkip = async (reason: ParticipantDeletionReason) => {
    // TODO: This ought to move to OpenAPI at some point
    await axios.put(responsesApi.cancel.path({ id: responseId }), {
      deletion_reason: reason,
    })

    const dashboardPath = ROUTES.DASHBOARD.path

    window.location.href =
      reason === ParticipantDeletionReason.Skipped
        ? dashboardPath
        : testInterfaceApi.flagged.path()
  }

  return (
    <>
      <Center w="full" h="full">
        <Flex direction="column" maxW="400px" gap={4} mt={10}>
          {!pipIsOpen && (
            <Flex direction="column" gap={4}>
              <Button onClick={() => setPipKey(Math.random())}>
                Re-open popup window
              </Button>
            </Flex>
          )}

          <Flex direction="column" gap={4} px={4}>
            <Text>
              If you accidentally closed the task window, you can reopen it
              here:
            </Text>

            <Flex direction="column" gap={4}>
              <Button onClick={openTaskTab}>Re-open task</Button>
            </Flex>
          </Flex>

          <Flex bg="white" rounded="md">
            <ExternalStudyControlPanel
              externalStudy={usabilityTestSection.external_study!}
              onClose={handleEndTask}
              onCheckCode={checkCode}
              validCode={validCode}
              onReport={() => setIsReportModalOpen(true)}
            />
          </Flex>
        </Flex>
      </Center>

      {!forceClosePip && (
        <DocumentPictureInPicture
          key={pipKey}
          onOpen={() => setPipIsOpen(true)}
          onClose={() => setPipIsOpen(false)}
        >
          <ExternalStudyControlPanel
            externalStudy={usabilityTestSection.external_study!}
            onClose={handleEndTask}
            onCheckCode={checkCode}
            validCode={validCode}
            onReport={() => setShowFullPageReportForm(true)}
          />

          <FullPageSkipForm
            isOpen={showFullPageReportForm}
            onClose={() => setShowFullPageReportForm(false)}
            onSkip={handleSkip}
            language={language}
          />
        </DocumentPictureInPicture>
      )}

      {language && (
        <SkipUsabilityTestModal
          isOpen={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          onSkip={handleSkip}
          language={language}
        />
      )}
    </>
  )
}

const FullPageSkipForm: React.FC<
  {
    isOpen: boolean
  } & ComponentProps<typeof SkipUsabilityTestForm>
> = ({ isOpen, ...skipFormProps }) => {
  return (
    <Flex
      direction="column"
      display={isOpen ? "flex" : "none"}
      position="absolute"
      top={0}
      left={0}
      h="full"
      bg="white"
      gap={2}
      p={4}
      overflowY="scroll"
    >
      <Heading as="h3">Are you sure you want to skip this test?</Heading>
      <Text fontSize="md" fontWeight="normal">
        Please select the reason you want to skip it.
      </Text>
      <Box mt="auto">
        <SkipUsabilityTestForm {...skipFormProps} />
      </Box>
    </Flex>
  )
}
