import {
  Alert,
  AlertIcon,
  Box,
  Checkbox,
  Flex,
  FormLabel,
  HStack,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react"
import React, {
  ChangeEvent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react"

import { SubmitButton } from "Components/button/submit-button"
import { JsForm } from "Components/form/form"
import { axios, isBadRequestError } from "Services/axios"
import { Account, Fluency, Language, Tester } from "Types"
import { LanguagesField } from "UserCrowd/components/LanguagesField/LanguagesField"
import { Card, CardList, CardListItem } from "UserCrowd/components/TestersCard"
import { apiAdminDemographicProfiles } from "~/api"
import TestersApi from "~/api/admin/testersApi"
import { TextInput } from "./form/text-input/text-input"

const useFluencies = (testerId: number) => {
  interface FluenciesResponse {
    fluencies: Fluency[]
  }

  const [fluencies, setFluencies] = useState<Fluency[] | null>(null)

  useEffect(() => {
    const inner = async () => {
      const response =
        await apiAdminDemographicProfiles.fluencies<FluenciesResponse>({
          id: testerId,
        })
      setFluencies(response.fluencies)
    }

    void inner()
  }, [testerId])

  return fluencies
}

interface Props {
  languages: Language[]
  persistedAccount: Readonly<Account>
  persistedTester: Readonly<Tester>
  notifyAssignmentsByEmail: boolean
}

export const AdminEditTester: React.FC<React.PropsWithChildren<Props>> = ({
  persistedAccount,
  persistedTester,
  notifyAssignmentsByEmail: initNotifyAssignmentsByEmail,
}) => {
  const toast = useToast()

  const [account, setAccount] = useState(persistedAccount)
  const [tester, setTester] = useState(persistedTester)
  const [notifyAssignmentsByEmail, setNotifyAssignmentsByEmail] = useState(
    initNotifyAssignmentsByEmail
  )
  const [isSubmitting, setIsSubmitting] = useState(false)

  const fluencies = useFluencies(tester.id)

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setTester((t) => {
      return { ...t, email: event.target.value }
    })
  }

  const handlePaypalEmailAddressChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setAccount((a) => {
      return { ...a, paypal_email_address: event.target.value }
    })
  }

  const handleEmailPreferenceChange =
    (field: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      setTester((t) => {
        return { ...t, [field]: event.target.checked }
      })
    }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      await axios.put(TestersApi.update.path({ id: tester.id }), {
        user: tester,
        account,
        notify_assignments_by_email: notifyAssignmentsByEmail,
      })

      window.location.href = TestersApi.destroy.path({
        id: tester.account_id,
      })
    } catch (error) {
      setIsSubmitting(false)
      if (isBadRequestError(error)) {
        toast({
          title: error.response.data.errors,
          status: "error",
        })
      } else {
        throw error
      }
    }
  }

  return (
    <>
      <JsForm onSubmit={handleSubmit}>
        <Text mb={4} fontSize="lg" fontWeight="bold">
          General
        </Text>
        <Card padding="none">
          <CardList>
            <CardListItem>
              <Flex align="center">
                <FlexLabel>Email</FlexLabel>
                <Fields>
                  <TextInput
                    type="email"
                    value={tester.email}
                    onChange={handleEmailChange}
                  />
                </Fields>
              </Flex>
              <Alert status="warning" mt={2}>
                <AlertIcon />
                Warning, changing a user's email will require the panelist to
                click a link in a confirmation email that will be sent to their
                new email address. You will not see the changes reflected until
                the panelist clicks the link.
              </Alert>
            </CardListItem>
            <CardListItem>
              <Flex align="center">
                <FlexLabel>PayPal email address</FlexLabel>
                <Fields>
                  <TextInput
                    type="email"
                    value={account.paypal_email_address || ""}
                    onChange={handlePaypalEmailAddressChange}
                  />
                </Fields>
              </Flex>
            </CardListItem>
            <CardListItem>
              <Flex align="center">
                <FlexLabel>Email preferences</FlexLabel>
                <Fields>
                  <Stack>
                    <Checkbox
                      variant="mdWithSmFont"
                      isChecked={notifyAssignmentsByEmail}
                      onChange={() => setNotifyAssignmentsByEmail((v) => !v)}
                    >
                      <Text color="text.primary">Assignment notifications</Text>
                    </Checkbox>
                    <Checkbox
                      variant="mdWithSmFont"
                      isChecked={tester.email_product_updates}
                      onChange={handleEmailPreferenceChange(
                        "email_product_updates"
                      )}
                    >
                      <Text color="text.primary">Product updates</Text>
                    </Checkbox>
                  </Stack>
                </Fields>
              </Flex>
            </CardListItem>
          </CardList>
        </Card>
        <SubmitButton isLoading={isSubmitting} loadingAction="Saving" mt={4}>
          Save
        </SubmitButton>
      </JsForm>
      <Box mt={8}>
        <Text mb={4} fontSize="lg" fontWeight="bold">
          Panelists Fluencies
        </Text>
        <Alert status="warning" mb={4}>
          <AlertIcon />
          Warning, updating happens when modifying the input (not with the save
          button)
        </Alert>
        {fluencies ? (
          <LanguagesField
            isAdmin
            testerId={tester.id}
            initialFluencies={fluencies}
            setIsLanguageAnswered={() => {
              /* noop */
            }}
          />
        ) : (
          <HStack>
            <Spinner size="xs" />
            <Text>Loading&hellip;</Text>
          </HStack>
        )}
      </Box>
    </>
  )
}

const Fields: React.FC<PropsWithChildren> = ({ children }) => (
  <Flex grow={1} shrink={1} basis={"250px"}>
    {children}
  </Flex>
)

const FlexLabel: React.FC<PropsWithChildren> = ({ children }) => (
  <FormLabel my={0} fontSize="md" fontWeight="medium" flexBasis="50%">
    {children}
  </FormLabel>
)
