import { Dispatch } from "Redux/app-store"
import { deleteSection } from "Redux/reducers/test-builder-form/action-creators/sections"
import { getSectionByClientId } from "Redux/reducers/test-builder-form/selectors/sections"
import { ClientId, UsabilityTestSectionType } from "Types"
import {
  SectionIndexProvider,
  SectionProvider,
  SectionTypeProvider,
} from "UsabilityHub/contexts"
import React, { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CardSortSectionCard } from "../TestFormCards/SectionCards/CardSortSectionCard/CardSortSectionCard"
import { DesignSurveySectionCard } from "../TestFormCards/SectionCards/DesignSurveySectionCard/DesignSurveySectionCard"
import { ExternalStudySectionCard } from "../TestFormCards/SectionCards/ExternalStudySectionCard/ExternalStudySectionCard"
import { FirstClickSectionCard } from "../TestFormCards/SectionCards/FirstClickSectionCard/FirstClickSectionCard"
import { FiveSecondSectionCard } from "../TestFormCards/SectionCards/FiveSecondSectionCard/FiveSecondSectionCard"
import { InstructionSectionCard } from "../TestFormCards/SectionCards/InstructionSectionCard/InstructionSectionCard"
import { NavigationSectionCard } from "../TestFormCards/SectionCards/NavigationSectionCard/NavigationSectionCard"
import { PreferenceSectionCard } from "../TestFormCards/SectionCards/PreferenceSectionCard/PreferenceSectionCard"
import { PrototypeSectionCard } from "../TestFormCards/SectionCards/PrototypeSectionCard/PrototypeSectionCard"
import { QuestionsSectionCard } from "../TestFormCards/SectionCards/QuestionsSectionCard/QuestionsSectionCard"
import { TreeTestSectionCard } from "../TestFormCards/SectionCards/TreeTestSectionCard/TreeTestSectionCard"
import { TestFormSectionErrorBound } from "./section-error-bound"

const getSectionComponent = (sectionType: UsabilityTestSectionType) => {
  switch (sectionType) {
    case UsabilityTestSectionType.CardSort:
      return <CardSortSectionCard />
    case UsabilityTestSectionType.DesignQuestions:
      return <DesignSurveySectionCard />
    case UsabilityTestSectionType.FirstClickTest:
      return <FirstClickSectionCard />
    case UsabilityTestSectionType.FiveSecondTest:
      return <FiveSecondSectionCard />
    case UsabilityTestSectionType.Information:
      return <InstructionSectionCard />
    case UsabilityTestSectionType.NavigationTest:
      return <NavigationSectionCard />
    case UsabilityTestSectionType.PreferenceTest:
      return <PreferenceSectionCard />
    case UsabilityTestSectionType.Questions:
      return <QuestionsSectionCard />
    case UsabilityTestSectionType.PrototypeTask:
      return <PrototypeSectionCard />
    case UsabilityTestSectionType.TreeTest:
      return <TreeTestSectionCard />
    case UsabilityTestSectionType.ExternalStudy:
      return <ExternalStudySectionCard />
  }
}

interface SectionProps {
  sectionClientId: ClientId
  sectionIndex: number
  sectionType: UsabilityTestSectionType
}

export const Section: React.FC<React.PropsWithChildren<SectionProps>> = memo(
  ({ sectionClientId, sectionIndex, sectionType }) => {
    const dispatch: Dispatch = useDispatch()
    const onDeleteSection = () => dispatch(deleteSection(sectionIndex))
    const section = useSelector(getSectionByClientId(sectionClientId))
    const component = getSectionComponent(sectionType)
    return (
      <TestFormSectionErrorBound
        onDeleteSection={onDeleteSection}
        sectionIndex={sectionIndex}
      >
        <SectionProvider value={{ section }}>
          <SectionTypeProvider value={sectionType}>
            <SectionIndexProvider value={sectionIndex}>
              {component}
            </SectionIndexProvider>
          </SectionTypeProvider>
        </SectionProvider>
      </TestFormSectionErrorBound>
    )
  }
)

Section.displayName = "Section"
