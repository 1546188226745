import React from "react"

import { SectionCard } from "../SectionCard"
import { SectionLogicActions } from "../TestLogicActions/TestLogicActions"

// TODO import this once MOT-747 is ready to ship
// import { AttachInstructionsMedia } from "./AttachInstructionsMedia"
import { InstructionSectionMessage } from "./InstructionSectionMessage"

export const InstructionSectionCard: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <SectionCard>
      <SectionLogicActions />
      <InstructionSectionMessage />
      {/* TODO render this once MOT-747 is ready to ship */}
      {/* <AttachInstructionsMedia /> */}
    </SectionCard>
  )
}
